import React, { useRef, useState } from 'react';

import Info from './Tabs/info';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Button, Drawer, theme } from 'antd';

import './index.scss';
// import 'react-spring-bottom-sheet/dist/style.css'


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsView = function (props) {
  const [value, setValue] = useState(0);
  const [position, setPosition] = useState(false);
  const sheetRef = useRef<BottomSheetRef>()

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  };

  return (
    <div style={{bottom: 20}}>
      <div className='flex-col bg-white shado-lg flex w-full justify-center' style={{alignItems: 'center'}}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Info" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Info />
          </TabPanel>
        </Box>
      </div>
    </div>
  )
}

export default TabsView