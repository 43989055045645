import { gql, useQuery } from '@apollo/client';
import { Dish } from 'components/dish';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  myRestaurant,
  myRestaurantVariables,
} from '__generated__/myRestaurant';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryPie,
} from 'victory';
import React from 'react';
import foodClient from "clients/food";

export const MY_RESTAURANT_QUERY = gql`
  query myRestaurant($input: MyRestaurantInput!) {
    myRestaurant(input: $input) {
      ok
      error
      restaurant {
        id
        name
        coverImg
        category {
          name
        }
        address
        isPromoted
        menu {
          id
          Title
          Price
          Description
          coverImg
          Photo
        }
      }
    }
  }
`;

interface IParams {
  id: string;
}

export const MyRestaurant = () => {
  const { id } = useParams<IParams>();
  const { data } = useQuery<myRestaurant, myRestaurantVariables>(
    MY_RESTAURANT_QUERY,
    {
      variables: {
        input: {
          id: +id,
        },
      },
      client: foodClient
    }
  );
  console.log(data);

  const chartData = [
    { x: 1, y: 3000 },
    { x: 2, y: 1500 },
    { x: 3, y: 4200 },
    // { x: 4, y: 2300 },
    // { x: 5, y: 7100 },
    // { x: 6, y: 6500 },
    // { x: 7, y: 4500 },
  ];

  // const { data: subscriptionData } = useSubscription<pendingOrders>(
  //   PENDING_ORDERS_SUBSCRIPTION
  // );

  // const history = useHistory();

  // useEffect(() => {
  //   if (subscriptionData?.pendingOrders.id) {
  //     history.push(`/orders/${subscriptionData.pendingOrders.id}`);
  //   }
  // }, [subscriptionData]);

  return (
    <div>
      <div
        className="bg-gray-700 bg-center bg-cover py-28"
        style={{
          backgroundImage: `url(${data?.myRestaurant.restaurant?.coverImg})`,
        }}
      ></div>
      <div className="container mt-10">
        <h2 className="mb-10 text-4xl font-medium">
          {data?.myRestaurant.restaurant?.name || 'Loading...'}
        </h2>
        <div className="flex">
          <Link
            to={`/restaurants/${id}/add-dish`}
            className="px-10 py-3 mr-8 text-white bg-gray-800"
          >
            Add Dish &rarr;
          </Link>
          <Link to={``} className="px-10 py-3 text-white bg-lime-700">
            Buy Promotion &rarr;
          </Link>
        </div>
        <div className="mt-10">
          {data?.myRestaurant.restaurant?.menu.length === 0 ? (
            <h4>this restaurant is empty</h4>
          ) : (
            <div className="grid mt-16 md:grid-cols-3 gap-x-5 gap-y-10">
              {data?.myRestaurant.restaurant?.menu.map((dish, index) => (
                <Dish
                  key={index}
                  name={dish.Title}
                  coverImg={dish.coverImg}
                  description={dish.Description}
                  price={dish.Price}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
