import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultWorkSchedules from './utilities/DefaultWorkSchedules.utility';
import { useTranslation } from 'react-i18next';
import RangePicker from './RangePicker';
import './WorkSchedule.scss';
import { Button, message } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import food from 'clients/food';
import { SAVE_SCHEDULE_MUTATION } from 'graphql/mutation';
import { GET_SCHEDULE } from 'graphql/query';

function WorkSchedule() {
  // const { workSchedule } = useSelector((state: any) => state.onboarding);
  const [schedules, setSchedules] = useState(DefaultWorkSchedules);
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const handleScheduleCheckbox = (scheduleToModify) => {
    const newSchedules = schedules.map((schedule) => {
      if (schedule.Day !== scheduleToModify.Day) return schedule;

      return { ...scheduleToModify, Work: !schedule.Work };
    });
    setSchedules(newSchedules);
  };
  const databaseFormat = 'HH:mm';

  const translateDay = (day) => {
    switch (day.toLowerCase()) {
      case 'monday':
        return t('workSchedule.days.monday');
      case 'tuesday':
        return t('workSchedule.days.tuesday');
      case 'wednesday':
        return t('workSchedule.days.wednesday');
      case 'thursday':
        return t('workSchedule.days.thursday');
      case 'friday':
        return t('workSchedule.days.friday');
      case 'saturday':
        return t('workSchedule.days.saturday');
      case 'sunday':
        return t('workSchedule.days.sunday');
      default:
        return 'Lunes';
    }
  };

  const handleScheduleChange = (scheduleModified, event) => {
    console.log(event);
    const newSchedules = schedules.map((schedule) => {
      if (schedule.Day !== scheduleModified.Day) return schedule;

      return {
        ...scheduleModified,
        InitialDate: event[0]?.format(databaseFormat),
        EndDate: event[1]?.format(databaseFormat)
      };
    });
    setSchedules(newSchedules);
  };

  const [saveWorkScheduleMutation, { loading }] = useMutation(SAVE_SCHEDULE_MUTATION, {
    client: food
  });

  const { data, loading: loadingData, refetch } = useQuery(
    GET_SCHEDULE,
    {
      client: food
    }
  )

  const saveWorkingSchedule = async () => {
    saveWorkScheduleMutation({
      variables: {
        input: {
          schedules
        }
      },
      onCompleted: ({saveSchedule}) => {
        if (saveSchedule?.ok) {
          if (saveSchedule?.schedules) {
            setSchedules(saveSchedule?.schedules);
          }
          message.success('Horario de trabajo guardado correctamente.');
        }
      },
      onError: () => {
        message.info('Ocurrio un error');
      }
    })
  }

  useEffect(() => {
    if (Array.isArray(data?.getSchedule?.schedules) && data?.getSchedule?.schedules.length > 0) {
      setSchedules(data?.getSchedule?.schedules);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="WorkSchedule mt-10">
      <h2 className="WorkSchedule__title">
        {t('workSchedule.title')}
      </h2>
      <p className="WorkSchedule__subtitle">
        {t('workSchedule.subtitle')}
        <br /> {t('workSchedule.description')}
      </p>

      <p className="WorkSchedule__subtitle"></p>

      {schedules.map((schedule) => (
        <RangePicker
          key={schedule.Day}
          schedule={schedule}
          translateDay={translateDay}
          handleScheduleChange={handleScheduleChange}
          handleScheduleCheckbox={handleScheduleCheckbox}
        />
      ))}

      <div className="WorkingHours__buttons flex-row">
        <Button
          className="Button Button--primary"
          onClick={() => saveWorkingSchedule()}
          loading={loading || loadingData}
          disabled={loading || loadingData}
        >
          {t('buttonSave')}
        </Button>
      </div>
    </div>
  );
}

export default WorkSchedule;
