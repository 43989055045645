import { gql } from "@apollo/client";
import { FULL_ORDER_FRAGMENT } from "fragments";

export const ORDER_SUBSCRIPTION = gql`
  subscription orderUpdates($input: OrderUpdatesInput!) {
    orderUpdates(input: $input) {
      id
      status
      total
      driver {
        email
      }
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;