import { useSubscription } from '@apollo/client';
import request from 'clients/request';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pendingOrders } from '__generated__/pendingOrders';
import { PENDING_ORDERS_SUBSCRIPTION } from './graphql';
import { Typography } from 'antd';
const { Title } = Typography;

export const NewOrderComponent = ({mp3}) => {
    const history = useHistory();
    const [NewOrder, setNewOrder] = useState(false);

    const { data: subscriptionData } = useSubscription<pendingOrders>(
        PENDING_ORDERS_SUBSCRIPTION,
        {
            client: request
        }
    );

    useEffect(() => {
        if (subscriptionData?.pendingOrders.id) {
            setNewOrder(true);
            mp3.loop = true;
            mp3.play();
        }
    }, [subscriptionData]);

    const acceptOrder = () => {
        setNewOrder(false);
        mp3.pause();
        mp3.currentTime = 0;
        history.push(`/orders/${subscriptionData.pendingOrders.id}`);
    }

    return (
        <>
            {NewOrder && <div id="acceptorder" style={{
                backgroundColor: "#cc5500",
                position: "fixed",
                transition: "all 2s ease-in-out",
                width: "100%",
                height: "100%",
                top: "0px",
                left: "0px",
                zIndex: 1000
            }}>
                <div onClick={acceptOrder} className="d-flex h-100 justify-content-center flex-column align-items-center">
                    <audio autoPlay={true} id="backgroundMusic">
                        <source src={"https://creatorassets.com/cdn/notification-sound-effects/sound-5.mp3"} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                    <div className="ticket_number">
                        <Title level={1} style={{ color: '#fff' }}>
                            {subscriptionData?.pendingOrders.id}
                        </Title>
                    </div>
                    <Title level={1} style={{ color: '#fff' }}>New order</Title>
                    <strong>Tap anywhere to accept</strong>
                </div>
            </div>}
        </>
    )
}