import React from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
import CheckFalse from 'assets/images/icons/no-seleccionado.png';
import CheckTrue from 'assets/images/icons/seleccionado.png';
import { Image } from 'antd'
function RangePicker({
  schedule,
  translateDay,
  handleScheduleChange,
  handleScheduleCheckbox
}) {
  const timePickerFormat = 'hh:mma';

  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      const button = document.querySelector(
        `.ant-picker-dropdown:not(.ant-picker-dropdown-hidden) .ant-btn.ant-btn-primary`
      );
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      button.dispatchEvent(event);
    }
  };

  return (
    <div className="WorkSchedule__schedule">
      <p className="WorkSchedule__schedule_day">{translateDay(schedule.Day)}</p>
      <TimePicker.RangePicker
        size="large"
        defaultValue={[
          moment(schedule.InitialDate, timePickerFormat),
          moment(schedule.EndDate, timePickerFormat)
        ]}
        value={[
          moment(schedule.InitialDate, timePickerFormat),
          moment(schedule.EndDate, timePickerFormat)
        ]}
        format={timePickerFormat}
        className="WorkSchedule__schedule_input"
        name={`${schedule.Day}_InitialDate`}
        onChange={(values) => handleScheduleChange(schedule, values)}
        onOpenChange={handleOpenChange}
      />
      <Image
        preview={false}
        src={schedule.Work ? CheckTrue : CheckFalse}
        className="WorkSchedule__schedule_checkbox"
        onClick={() => handleScheduleCheckbox(schedule)}
      />
    </div>
  );
}

export default RangePicker;
