import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { MY_DISH_QUERY, MY_RESTAURANT_QUERY } from 'pages/restaurant/graphql';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { createDish, createDishVariables } from '__generated__/createDish';
import foodClient from "clients/food";
import { Image, message, Select, UploadProps } from 'antd';
import { GetCategories } from 'graphql/query/categories';
import Dragger from 'antd/lib/upload/Dragger';
import Logo from 'assets/images/logo.png';
import { beforeUpload } from 'utils';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

const CREATE_DISH_MUTATION = gql`
  mutation createDish($input: CreateDishInput!) {
    createDish(input: $input) {
      ok
      error
    }
  }
`;

const EDIT_DISH_MUTATION = gql`
  mutation editDish($input: EditDishInput!) {
    editDish(input: $input) {
      ok
      error
    }
  }
`;

interface IParams {
  restaurantId: string;
  dishId?: string; 
}

interface IFormProps {
  name: string;
  price: string;
  description: string;
  [key: string]: string;
}

export const AddDish = () => {
  const { restaurantId, dishId } = useParams<IParams>();
  const history = useHistory();
  const isEditing = window.location.pathname.includes('edit')
  const [imageUrl, setImageUrl] = useState(null);
  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);

  const [editDishMutation, { loading: loadingEdit }] = useMutation<
    createDish,
    createDishVariables
  >(EDIT_DISH_MUTATION, {
    refetchQueries: [
      {
        query: MY_RESTAURANT_QUERY,
        variables: {
          input: {
            id: +restaurantId,
          },
        },
      },
    ],
    client: foodClient,
    onError: (err: ApolloError) => {
      message.error(err.message);
    },
  });

  const [createDishMutation, { loading }] = useMutation<
    createDish,
    createDishVariables
  >(CREATE_DISH_MUTATION, {
    refetchQueries: [
      {
        query: MY_RESTAURANT_QUERY,
        variables: {
          input: {
            id: +restaurantId,
          },
        },
      },
    ],
    client: foodClient,
    onError: (err: ApolloError) => {
      message.error(err.message);
    },
  });


    

  const { data: categories } = useQuery(
    GetCategories,
    {
      client: foodClient
    }
  );

  const {
    register,
    getValues,
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useForm<IFormProps>({ mode: 'onChange' });
  const onSubmit = () => {
    const { Title, Price, Description, Category, file, ...rest } = getValues();
    const optionObjects = optionsNumber.map((theId) => ({
      Title: rest[`${theId}-optionTitle`],
      extra: +rest[`${theId}-optionExtra`],
    }));
    if (!isEditing && (file === undefined || file === null)) {
      message.info('Please select a file.');
      setUploading(false);
      return
    }
    const coverImg: File | string = file;

    if (isEditing) {
      editDishMutation({
        variables: {
          input: {
            Title,
            Price: +Price,
          Description,
          coverImg,
          dishId: Number(dishId),
          restaurantId: +restaurantId,
          CategoryId: Number(Category)
        },
      },
      onCompleted: () => {
        history.goBack();
      },
      onError(error) {
        message.info(error.message)
      },
    });
    } else {
      createDishMutation({
          variables: {
            input: {
              Title,
              Price: +Price,
            Description,
            coverImg,
            restaurantId: +restaurantId,
            CategoryId: Number(Category)
          },
        },
        onCompleted: () => {
          history.goBack();
        },
        onError(error) {
          message.info(error.message)
        },
      });
    }
  };

  const [optionsNumber, setOptionsNumber] = useState<number[]>([]);
  const onAddOptionClick = () => {
    setOptionsNumber((current) => [Date.now(), ...current]);
  };
  const onDeleteClick = (idToDelete: number) => {
    setOptionsNumber((current) => current.filter((id) => id !== idToDelete));
    setValue(`${idToDelete}-optionName`, '');
    setValue(`${idToDelete}-optionExtra`, '');
  };

  const { data } = useQuery(
    MY_DISH_QUERY,
    {
      variables: {
        input: {
          id: +dishId,
        },
      },
      client: foodClient
    }
  );

  useEffect(() => {
    if (data && data?.myDish?.dish) {
      console.log(data?.myDish?.dish);
      for (const key in data?.myDish?.dish) {
        if (Object.prototype.hasOwnProperty.call(data?.myDish?.dish, key)) {
          const element = data?.myDish?.dish[key];
          const values = getValues();
          if (element && Object.prototype.hasOwnProperty.call(values, key)) {
            setValue(key, element)
          }
          
        }
      }
    }
  }, [data, getValues, setValue]);


  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      setValue('file', file);
      setImageUrl(URL.createObjectURL(file));
      onSuccess("Ok");
    } catch (err) {
      if (err instanceof Error) {
        onError({ err });
      }
    }
  }

  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      // setLoading(false);
    }
  }


  return (
    <div className="container flex flex-col items-center mt-2">
      <Helmet>
        <title>Add Dish | SMARTFOOD</title>
      </Helmet>
      <h4 className="mb-3 text-2xl font-semibold">{isEditing ? 'Edit' : "Add"} Dish</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid w-full max-w-screen-sm gap-3 mt-5 mb-5"
      >
          <div>
            <Dragger
              className='dragger-img'
              name="file"
              accept="image/*"
              customRequest={uploadImage}
              beforeUpload={beforeUpload}
              onChange={handleChangeImage}
              showUploadList={false}
              disabled={componentDisabled}
              height={300}>
              <img style={{width: 300, maxHeight: 300}} alt="imageUrl" src={imageUrl || Logo} />
              <p className="ant-upload-hint">Haga clic o arrastre el archivo</p>
            </Dragger>
        </div>
        <input
          {...register('Title', {
            required: 'Title is required',
          })}
          type="text"
          placeholder="Title"
          className="input"
        />
        <input
          {...register('Price', {
            required: 'Price is required',
          })}
          min={0}
          type="number"
          placeholder="Price"
          className="input"
        />
        <input
          {...register('Description', {
            required: 'Description Name is required',
          })}
          type="text"
          placeholder="Description"
          className="input"
        />

        <select
          className="input"
          {...register('Category', {
            required: true,
          })}
          onChange={e => console.log(e.target.id)}
        >
          {categories?.allCategories?.categories.map((category, index) => (
            <option key={index} value={category.id}>{category.name}</option>
          ))}
        </select>
        <div className="my-10">
          <h4 className="mb-3 text-lg font-medium">Dish Options</h4>
          <span
            onClick={onAddOptionClick}
            className="px-2 py-1 mt-5 text-white bg-gray-900 cursor-pointer "
          >
            Add Dish Option
          </span>
          {optionsNumber.length !== 0 &&
            optionsNumber.map((id) => (
              <div key={id} className="mt-5">
                <input
                  {...register(`${id}-optionName`)}
                  className="px-4 py-2 mr-3 border-2 focus:outline-none focus:border-gray-600"
                  type="text"
                  placeholder="Option Name"
                />
                <input
                  {...register(`${id}-optionExtra`)}
                  className="px-4 py-2 border-2 w-36 focus:outline-none focus:border-gray-600"
                  type="number"
                  min={0}
                  placeholder="Option Extra"
                />
                <span
                  className="px-4 py-3 mt-5 ml-3 text-white bg-red-500 cursor-pointer"
                  onClick={() => onDeleteClick(id)}
                >
                  Delete Option
                </span>
              </div>
            ))}
        </div>

        <Button loading={loading || uploading} canClick={isValid} actionText={`${isEditing ? "Edit" : 'Create'} Dish`} />
      </form>
    </div>
  );
};
