import {
  SET_SOCKET_CONNECTION,
  GET_SOCKET_CONNECTION
} from "constants/ActionTypes";
import io from 'socket.io-client';
// const INIT_STATE = io(`${process.env.REACT_APP_DAILY_REQUEST_API_SOCKET}/driver`);


export default (state = {}, action) => {
  switch (action.type) {

    case SET_SOCKET_CONNECTION: {
      return {
        ...state,
        socket: action.payload,
      }
    }

    default:
      return state;
  }
}
