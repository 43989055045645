import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_es from 'lngProvider/locales/es_ES.json';
import global_en from 'lngProvider/locales/en_US.json';
import AppLocale from "lngProvider";
import { IRootState } from "interfaces/IRootState";
import { App } from 'components/app';
import { HelmetProvider } from 'react-helmet-async';


i18next.init({
  interpolation: { escapeValue: true },
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  },
  lng: 'es'
});


const MainApp = () => {

  const { locale, themeColor } = useSelector((state: IRootState) => state.settings);

  const currentAppLocale = AppLocale[locale.locale];

  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `/css/${themeColor}.css`;  //This line is changed, this comment is for explaination purpose.

    link.className = 'gx-style';
    document.body.appendChild(link);
  }, []);

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <I18nextProvider i18n={i18next}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </IntlProvider>
      </I18nextProvider>
    </ConfigProvider>
  )
};

export default memo(MainApp);
