import { ApolloError, gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Avatar, Image, message, Select, Space, UploadProps } from 'antd';
import food from 'clients/food';
import ReactDOM from 'react-dom';
import { Button } from 'components/button';
import { FormError } from 'components/form-error';
import { MY_RESTAURANTS_QUERY } from 'pages/owner/my-restaurants';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { GOOGLE_MAPS_APIKEY } from 'const';
import Logo from 'assets/images/logo.png';
import {
  createRestaurant,
  createRestaurantVariables,
} from '__generated__/createRestaurant';
import Dragger from 'antd/lib/upload/Dragger';
import { beforeUpload, NameAddress } from 'utils';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { onGoogleApiLoaded } from 'utils/polygon';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMapPin
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MY_RESTAURANT_QUERY } from './my-restaurant';
import { IParams } from 'interfaces/IParams';
import { useParams } from 'react-router-dom';
import { myRestaurant, myRestaurantVariables } from '__generated__/myRestaurant';
import foodClient from "clients/food";
import { GetCategories } from 'graphql/query/categories';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Places from 'components/places';
const { Option } = Select;
const arr: any = [faMapPin]
library.add(
  ...arr
);

const CREATE_RESTAURANT_MUTATION = gql`
  mutation createRestaurant($input: CreateRestaurantInput!) {
    createRestaurant(input: $input) {
      error
      ok
      restaurantId
    }
  }
`;

const EDIT_RESTAURANT_MUTATION = gql`
  mutation editRestaurant($input: EditRestaurantInput!) {
    editRestaurant(input: $input) {
      error
      ok
      restaurantId
    }
  }
`;


const toBase64 = (file): any => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

interface IFormProps {
  name: string;
  email: string;
  address: string;
  categories: any[];
  file: File;
}

let interval = null;

const AnyReactComponent = ({ text }) => <FontAwesomeIcon markerWidth={1000} width={1000} style={{color: '#fb9805', width: 100}} icon={['fas', 'map-pin']} />;

export const AddRestaurant = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState(null);
  const [address, setAddress] = useState('');
  const [searchBox, setSearchBox] = useState<any>();
  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [zoom, setZoom] = useState(13);
  const [maps, setMaps] = useState(null);
  const [map, setMap] = useState(null);
  const isEditing = window.location.href.includes('edit');

  const [centerLocation, setCenterLocation] = useState({
    lat: null,
    lng: null
  });

  const [currentLocation, setCurrentLocation] = useState(null);
  const [selected, setSelected] = useState(null);

  const { data: categories } = useQuery(
    GetCategories,
    {
      client: foodClient
    }
  );

  const onCompleted = (data: createRestaurant) => {
    const {
      createRestaurant: { ok, restaurantId },
    } = data;
    if (ok) {
      setUploading(false);
      history.push('/restaurants');
    }
  };

  

  const onCompletedEdit = (data) => {
    const {
      editRestaurant: { ok, restaurantId },
    } = data;
    if (ok) {
      setUploading(false);
      history.push('/restaurants');
    }
  };
  const [createRestaurantMutation, { data }] = useMutation<
    createRestaurant,
    createRestaurantVariables
  >(CREATE_RESTAURANT_MUTATION, {
    onCompleted,
    refetchQueries: [
      {
        query: MY_RESTAURANTS_QUERY,
      },
    ],
    onError: (err: ApolloError) => {
      message.error(err.message);
    },
    client: food
  });

  const [editRestaurantMutation] = useMutation<
    createRestaurant,
    createRestaurantVariables
  >(EDIT_RESTAURANT_MUTATION, {
    onCompleted: onCompletedEdit,
    refetchQueries: [
      {
        query: MY_RESTAURANTS_QUERY,
      },
    ],
    onError: (err: ApolloError) => {
      message.error(err.message);
    },
    client: food
  });

  const {
    register,
    getValues,
    formState: { errors, isValid },
    formState,
    handleSubmit,
    setValue
  } = useForm<IFormProps>({
    mode: 'onChange',
  });

  useEffect(() => {
    console.log(errors);
    
  }, [errors])

  const [uploading, setUploading] = useState(false);
  const onSubmit = async () => {
    try {
      setUploading(true);
      const { name, email, categories, address, file } = getValues();
      
      if (!isEditing && (file === undefined || file === null)) {
        message.info('Por favor seleccionar un archivo.');
        setUploading(false);
        return
      }
      if (Array.isArray(categories) && categories.length < 1) {
        message.info('Por favor seleccionar una categoria.');
        setUploading(false);
        return
      }
      if ((centerLocation?.lat === null || centerLocation.lat === undefined) && (centerLocation?.lng === null || centerLocation.lng === undefined)) {
        message.info('Por favor seleccionar una direccion.');
        setUploading(false);
        return
      }
      const coverImg: File | string = file;
      if (!isEditing) {

        createRestaurantMutation({
            variables: {
            input: { name, address, email, categories, coverImg, location: [centerLocation?.lat, centerLocation?.lng] },
          },
          onCompleted,
          onError: () => {
            setUploading(false);
          }
        });
      } else {
        editRestaurantMutation({
          variables: {
            input: { name, restaurantId: Number(id), email, address, categories, coverImg, coverImgString: imageUrl, location: [centerLocation?.lat, centerLocation?.lng] },
          },
          onCompleted: onCompletedEdit,
          onError: () => {
            setUploading(false);
          }
        });
      }
    } catch (err) {
      debugger
      setUploading(false);
      console.log(err);
    }
  }

  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      // setLoading(false);
    }
  }

  const onSuccess = async ({ coords }) => {
    // const address = await NameAddress({ latitude: coords.latitude, longitude: coords.longitude });
    // setAddress(address);
    // setValue('address', address);
    // addMarker(new window.google.maps.LatLng(coords.latitude, coords.longitude));
    // setCurrentLocation({ lat: coords.latitude, lng: coords.longitude });
    // setCenterLocation({ lat: coords.latitude, lng: coords.longitude });
  }

  const { id } = useParams<IParams>();
  const { data: restaurant } = useQuery<myRestaurant, myRestaurantVariables>(
    MY_RESTAURANT_QUERY,
    {
      variables: {
        input: {
          id: +id
        }
      },
      client: foodClient
    }
  );

  const onError = (error: any) => {
    console.log(error)
  }
  
  useEffect(() => {
    if (window.location.href.includes('edit') && restaurant) {
      // const { name, categoryName, address } = getValues();
      const { name, address, category, coverImg, email, categories } = restaurant?.myRestaurant?.restaurant;
      setValue('name', name);
      setValue('email', email);
      setValue('address', address);
      setValue('categories', categories);
      setImageUrl(coverImg);
          
    }
  }, [id, restaurant]);

  useEffect(() => {
    
    navigator.geolocation.getCurrentPosition(onSuccess, onError, { enableHighAccuracy: true });
    return () => {
      // socket.close();

    }

  }, []);

  const draggerAddress = async (data) => {
    const {lat, lng} = data?.center;
    const address = await NameAddress({ latitude: lat(), longitude: lng() });
    setValue('address', address);
    setAddress(address);
    setCenterLocation({lat: lat(), lng: lng()});
    addMarker(new google.maps.LatLng(lat(), lng()));
  }

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      setValue('file', file);
      setImageUrl(URL.createObjectURL(file));
      onSuccess("Ok");
    } catch (err) {
      if (err instanceof Error) {
        onError({ err });
      }
    }
  }

  function addMarker(location: any) {
    new google.maps.Marker({
        position: location,
        map: map
    });
  }

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (selected?.lat && selected?.lng) {
      setZoom(15);
      setCenterLocation({lat: selected?.lat, lng: selected?.lng});
    }
  }, [selected])

  

  return (
    <div className="container flex flex-col items-center mt-4 mb-4">
      <Helmet>
        <title>Add Restaurant | SMARTFOOD</title>
      </Helmet>
      <h4 className="mb-3 text-2xl font-semibold">Add Restaurant</h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full gap-3"
      >
        <input
          {...register('name', {
            required: 'Name is required',
          })}
          type="text"
          placeholder="Name"
          className="input "
        />
         <input
          {...register('email', {
            required: 'Email is required',
          })}
          type="email"
          placeholder="Email"
          className="input "
        />

        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Category Name"
          defaultValue={[]}
          onChange={(e) => setValue('categories', e)}
          optionLabelProp="label"
        >
          {categories?.allCategories?.categories.map(category => <Option 
            key={category?.id} 
            value={category?.id} label={category?.name}>
            <Space>
              <span role="img" aria-label={category?.name}>
                <img src={category?.coverImg} style={{width: 50}} alt={category?.name} />
              </span>
              {category?.name} ({category?.description})
            </Space>
          </Option>)}
        </Select>
      
        <div>
            <Dragger
              className='dragger-img'
              name="file"
              accept="image/*"
              customRequest={uploadImage}
              beforeUpload={beforeUpload}
              onChange={handleChangeImage}
              showUploadList={false}
              disabled={componentDisabled}
              height={350}>
              <div className="flex flex-col">
                <img style={{width: 300, maxHeight: 350, alignSelf: 'center'}} alt="imageUrl" src={imageUrl || Logo} />
                <p className="ant-upload-hint">Haga clic o arrastre el archivo</p>
              </div>
            </Dragger>
        </div>

        <div
          className="w-full overflow-hidden"
          style={{ width: '100%', height: window.innerHeight }}
        >

          <LoadScript 
            googleMapsApiKey={GOOGLE_MAPS_APIKEY}  
            libraries={['places']}
          >

            <div className="places-container">
              <Places setAddress={setValue} setSelected={setSelected} />
            </div>
            <GoogleMap
              mapContainerStyle={{ 
                width: window.innerWidth, 
                height: window.innerHeight
              }}
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={{ lat: centerLocation?.lat, lng: centerLocation?.lng }}
              zoom={zoom}
              options={{
                gestureHandling: "greedy"
              }}
            >
              {selected && <Marker position={selected} />}
            </GoogleMap>
          </LoadScript>

        </div>

        <Button
          loading={uploading}
          canClick={isValid}
          actionText={`${isEditing ? "Update" : "Create"} Restaurant`}
        ></Button>
        {data?.createRestaurant?.error && (
          <FormError errorMessage={data.createRestaurant.error} />
        )}
      </form>
    </div>
  );
};
