import { gql, useMutation, useQuery } from '@apollo/client';
import { List, Typography, Card, Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import IntlMessages from 'utils/IntlMessages';
import { OrderStatus } from '__generated__/globalTypes';
import { format } from 'date-fns'
import { editOrder, editOrderVariables } from '__generated__/editOrder';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'interfaces/IRootState';
import { useSelector } from 'react-redux';
import Logo from 'assets/images/logo.png';
import clientFood from "../../clients/food";
import request from 'clients/request';
import "./inProgress.css";

const { confirm } = Modal;

const { Title } = Typography;

export const ORDERS_BY_RESTAURANT = gql`
  query Orders($input: GetOrdersInput!) {
    getOrders(input: $input) {
        ok
        error
        orders {
          id
          status
          restaurant {
            name
            address
            coverImg
          }
          customer {
              username
          }
        }
    }
  }
`;

const EDIT_ORDER = gql`
  mutation editOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      ok
      error
    }
  }
`;

export const InProgress = () => {

    const history = useHistory()
    const [page,setPage] = useState(0)
    

    const { data, loading, error, refetch } = useQuery(
        ORDERS_BY_RESTAURANT,
        {
            variables: {
              input: {
                skip: (page - 1) > 0 ? (page - 1) * 10 : 0,
                status: [OrderStatus.Pending, OrderStatus.Cooking],
              }
            },
            client: request
        }
    );

    console.log("THIS", data);
    

    useEffect(() => {
      refetch()
    }, [])

    const [editOrderMutation] = useMutation<editOrder, editOrderVariables>(
        EDIT_ORDER, 
        {
          client: request
        }
      );
    const { socket } = useSelector((state: IRootState) => state)


    const onButtonClick = (newStatus: OrderStatus, id: number) => {
        editOrderMutation({
          variables: {
            input: {
              id,
              status: newStatus
            },
          },
          onCompleted: (data) => {
            if (data.editOrder.ok) {
              history.push(`/delivery/${id}`);
            }
          }
        })
      };

    const ToReady = (status: OrderStatus, id: number) => {
        confirm({
            title: 'Confirmar acción.',
            icon: <ExclamationCircleOutlined />,
            content: `Order cooked #${id}`,
            onOk() {
                onButtonClick(status, id);
            }
          });
    }
    
    
    if (!data || loading || error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <span className="text-xl font-medium tracking-wide">Loading...</span>
            </div>
        );
    }

    return (
        <List
            header={<Title level={4}>
                <IntlMessages id="sidebar.inProgress" />
            </Title>}
            bordered
            pagination={{
              pageSize: 10,
              current: page,
              total: data?.getOrders?.totalResults,
              onChange: (page) => {
                  setPage(page);
              }
          }}
            dataSource={data?.getOrders?.orders ? data?.getOrders?.orders : []}
            renderItem={(item: any) => (
                <List.Item>
                    <Card style={{ width: 500 }}>
                       <div className="d-flex justify-content-between">
                          <div>
                            <img width={100} src={item?.restaurant?.coverImg || Logo} alt="" />
                          </div>
                          <div className="flex justify-between w-80">
                            <div className='flex flex-col'>
                                <strong className="text-capitalize">Customer: {item?.customer?.username}</strong>
                                <strong className="text-capitalize">Restaurant: {item?.restaurant?.name}</strong>
                                <p>Order: #{item.id}</p>
                            </div>
                            <div>
                                {item && item.createdAt && <strong>{ format(new Date(item.createdAt), 'p')}</strong>}
                                <Button onClick={() => ToReady(OrderStatus.Cooked, item.id)} style={{ width: 70, marginLeft: 20, marginBottom: 0 }}>
                                    <IntlMessages id="app.ready" />
                                </Button>
                            </div>
                          </div>
                       </div>
                    </Card>
                </List.Item>
            )}
        />
    )
}
