import { Order } from 'pages/order';
import { AddDish } from 'pages/owner/add-dish';
import { AddRestaurant } from 'pages/owner/add-restaurants';
import { MyRestaurant } from 'pages/restaurant/my-restaurant';
import { MyRestaurants } from 'pages/owner/my-restaurants';
import { ConfirmEmail } from 'pages/user/confirm-email';
import { EditProfile } from 'pages/user/edit-profile';
import { DashboardDriver } from 'pages/driver/dashboard';
import { DashboardOwner } from 'pages/owner/dashboard';
import { Orders } from 'pages/restaurant/orders';
import { InProgress } from 'pages/restaurant/inProgress';
import { Delivery } from 'pages/restaurant/delivery';
import { DeliveryMaps } from 'pages/restaurant/deliveryMaps';
import { Home } from 'pages/home';
import WorkSchedule from 'pages/restaurant/WorkSchedule';

export const commonRoutes = [
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/confirm',
        component: ConfirmEmail,
    },
    {
        path: '/edit-profile',
        component: EditProfile,
    }
];

export const restaurantRoutes = [
    {
        path: '/orders',
        component: Orders,
    },
    {
        path: '/orders/:id',
        component: Order,
    },
    {
        path: '/inProgress',
        component: InProgress
    },
    {
        path: '/edit-restaurant/:id',
        component: AddRestaurant,
    },
    {
        path: '/add-restaurant',
        component: AddRestaurant,
    },
    {
        path: '/restaurants/:restaurantId/add-dish',
        component: AddDish,
    },
    {
        path: '/restaurants/:restaurantId/edit-dish/:dishId',
        component: AddDish,
    },
    {
        path: '/menu/:id',
        component: MyRestaurant,
    },
    {
        path: '/restaurants',
        component: MyRestaurants,
    },
    {
        path: '/menu',
        component: MyRestaurants,
    },
    {
        path: '/schedule',
        component: WorkSchedule,
    },
    {
        path: '/delivery',
        component: Delivery
    },
    {
        path: '/delivery/:id',
        component: DeliveryMaps
    }
];

export const driverRoutes = [
    {
        path: '/orders',
        component: DashboardDriver,
    },
];

export const ownerRoutes = [
    {
        path: '/edit-restaurant/:id',
        component: AddRestaurant,
    },
    {
        path: '/add-restaurant',
        component: AddRestaurant,
    },
    {
        path: '/restaurants/:restaurantId/add-dish',
        component: AddDish,
    },
    {
        path: '/restaurants/:restaurantId/edit-dish/:dishId',
        component: AddDish,
    },
    {
        path: '/restaurants/:id',
        component: MyRestaurant,
    },
    {
        path: '/restaurants',
        component: MyRestaurants,
    },
];
