import { gql, useQuery } from '@apollo/client';
import { Restaurant } from 'components/restaurant';
import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { myRestaurants } from '__generated__/myRestaurants';
import foodClient from "clients/food";

export const MY_RESTAURANTS_QUERY = gql`
  query myRestaurants {
    myRestaurants {
      ok
      error
      restaurants {
        id
        name
        coverImg
        category {
          name
        }
        address
        isPromoted
      }
    }
  }
`;

export const GET_All_RESTAURANTS = gql`
    query restaurants($input: RestaurantsInput!) {
        restaurants(input: $input) {
            ok
            error
            results {
                id
                name
                coverImg
                category {
                    name
                }
                address
                isPromoted
            }
        }
    }
`;


export const MyRestaurants = () => {
  const { data, refetch } = useQuery(GET_All_RESTAURANTS, {
    variables: {
      input: {
        page: 1,
      },
    },
    client: foodClient
  });

  console.log(data);
  

  useEffect(() => {
    refetch();
  }, [])

  return (
    <div>
      <Helmet>
        <title>Restaurants | SMARTFOOD</title>
      </Helmet>
      <div className="mt-4 ml-4 max-w-screen-2xl">
        <h2 className="mb-10 text-4xl font-medium">Restaurants</h2>
        <Link
          className="text-lime-600 hover:underline"
          to="/add-restaurant"
        >
          Create one &rarr;
        </Link>
        {data?.restaurants?.ok &&
          data?.restaurants?.results?.length === 0 ? (
          <>
            <h4 className="mb-5 text-xl">You have no restaurants</h4>
          </>
        ) : (
          <div className="grid mt-16 md:grid-cols-3 gap-x-5 gap-y-10">
            {data?.restaurants?.results?.map((restaurant) => (
              <Restaurant
                key={restaurant?.id}
                id={restaurant?.id + ''}
                coverImg={restaurant?.coverImg}
                name={restaurant?.name}
                categoryName={restaurant?.category?.name}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
