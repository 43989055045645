import React from 'react';
import { useMe } from 'hooks/useMe';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from "antd";
import Sidebar from 'components/Sidebar';

import { NotFound } from 'pages/404';
import { driverRoutes, restaurantRoutes, commonRoutes } from 'utils/routes';
import SidebarContentCommon from './SidebarContent';
import { UserRole } from '__generated__/globalTypes';
import Topbar from 'components/Topbar';

const { Content } = Layout;

export const DashboardCommon = () => {

    const { data, loading, error } = useMe();

    if (!data || loading || error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <span className="text-xl font-medium tracking-wide">Loading...</span>
            </div>
        );
    }

    return (
        <Router>
            <Layout className="gx-app-layout">
                <Sidebar SidebarContent={SidebarContentCommon} />
                <Layout>
                    <Topbar />
                    <Content className={`gx-layout-content gx-container-wrap`}>
                        <Switch>
                            {data?.GetUser?.user?.role === UserRole.Restaurant &&
                                restaurantRoutes.map((route) => (
                                    <Route exact key={route.path} path={route.path}>
                                        <route.component />
                                    </Route>
                                ))}

                            {data?.GetUser?.user?.role === UserRole.Delivery &&
                                driverRoutes.map((route) => (
                                    <Route exact key={route.path} path={route.path}>
                                        <route.component />
                                    </Route>
                                ))}

                            {commonRoutes.map((route) => (
                                <Route exact key={route.path} path={route.path}>
                                    <route.component />
                                </Route>
                            ))}

                            <Route>
                                <NotFound />
                            </Route>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
};
