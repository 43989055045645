import { gql } from '@apollo/client/core';

export const GET_SCHEDULE = gql`
    query getSchedule {
        getSchedule {
            ok
            schedules {
                id
                Day
                Work
                InitialDate
                EndDate
                RestaurantId
            }
        }
    }
`;


export const GET_RESTAURANT_BY_USER = gql`
    query findRestaurantByUser {
        findRestaurantByUser {
            restaurant {
                id
            }
        }
    }
`;