import React from "react";
import TicketItem from "./TicketItem";

import Widget from "components/Widget";
const ticketList = [
  {
    id: 1,
    avatar: "https://via.placeholder.com/150",
    title: 'Need a quick support on setting',
    description: [<span className="gx-link" key={13}>Joy Parish</span>, "  created ticket 15 mins ago"],
    status: 2
  }, {
    id: 2,
    avatar: "https://via.placeholder.com/150",
    title: 'Pre-sale query about the product',
    description: [<span key={14} className="gx-link">You</span>, " replied 2 days ago"],
    status: 1
  }, {
    id: 3,
    avatar: "https://via.placeholder.com/150",
    title: 'Regarding customization service',
    description: [<span key={15} className="gx-link">Joy Parish</span>, " replied 2 days ago"],
    status: 4
  }
];

const TicketList = () => {

  return (
    <Widget title={
      <h2 className="h4 gx-text-capitalize gx-mb-0">
        Recent Tickets</h2>
    } styleName="gx-card-ticketlist"
      extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
        See all tickets <i
          className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
      </h5>}>
      {
        ticketList.map((ticket, index) => <TicketItem key={index} data={ticket} />)
      }
      <div className="gx-task-list-item gx-d-block gx-d-sm-none"><h5
        className="gx-text-primary gx-mb-0 gx-pointer">See all tickets <i
          className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" /></h5>
      </div>
    </Widget>
  );
}


export default TicketList;
