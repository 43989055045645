import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';

const container = document.getElementById('root');

const root = createRoot(container);

// Wrap the rendering in a function:
const render = (Component) => {
  root.render(
    // Wrap App inside AppContainer
    // <AppContainer>
      <Component />
    // </AppContainer>
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module["hot"]) {
  module["hot"].accept('./NextApp', () => {
    render(NextApp);
  });
}
