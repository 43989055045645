import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { GOOGLE_MAPS_APIKEY } from 'const';
import { FULL_ORDER_FRAGMENT } from 'fragments';
import { DirectionsRenderer, GoogleMap, LoadScript, Marker, DirectionsService } from '@react-google-maps/api';
import { IRootState } from 'interfaces/IRootState';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { cookedOrders } from '__generated__/cookedOrders';
import { takeOrder, takeOrderVariables } from '__generated__/takeOrder';
import { LOCALSTORAGE_TOKEN } from 'const';
import TabsView from 'components/TabsView';
import { useDispatch } from "react-redux";

import { OrderStatus } from '__generated__/globalTypes';
import "./dashboard.css";
import request from 'clients/request';
import { SetOrderSelected } from 'appRedux/actions/Order';
import { message } from 'antd';


const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const COOCKE_ORDERS_SUBSCRIPTION = gql`
  subscription cookedOrders {
    cookedOrders {
      ...FullOrderParts
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

const TAKE_ORDER_MUTATION = gql`
  mutation takeOrder($input: TakeOrderInput!) {
    takeOrder(input: $input) {
      ok
      error
    }
  }
`;

const CREATE_LOCATION_MUTATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ok
      error
      locationId
    }
  }
`;

const EDIT_LOCATION_MUTATION = gql`
  mutation editLocation($input: EditLocationInput!) {
    editLocation(input: $input) {
      ok
      error
    }
  }
`;

const GET_ORDER_INPROGRESS = gql`
  query getOrderDriver {
    getOrderDriver {
      ok
      error
      order {
        id
        status
        total
        driver {
          email
        }
        restaurantLocation {
          currentLocation
        }
        locationEntity {
          currentLocation
        }
        customer {
          email
        }
        restaurant {
          name
        }
      }
    }
  }
`;

interface ICoords {
  lat: number;
  lng: number;
}

let interval = null;

const flightPlanCoordinates = [
  { lat: 37.772, lng: -122.214 },
  { lat: 21.291, lng: -157.821 },
  { lat: -18.142, lng: 178.431 },
  { lat: -27.467, lng: 153.027 },
];

let locationId = null;

export const DashboardDriver = () => {
  const [driverCoords, setDriverCoords] = useState<ICoords>({ lng: 0, lat: 0 });
  const { socket } = useSelector((state: IRootState) => state);
  const { order: orderState } = useSelector((state: IRootState) => state.order);

  const [order, setOrder] = useState(null);
  const dispatch = useDispatch();
  const [directions, setDirections] = useState<google.maps.DirectionsResult>(null);

  const [editLocationMutation] = useMutation(
    EDIT_LOCATION_MUTATION,
    {
      client: request
    }
  );

  const directionsCallback = (response) => {
    if (directions === null || directions === undefined) {
      if (response.status === 'OK') {
        setDirections(response);
      } else {
        console.log('response: ', response)
      }
    }
  }

  const onSuccess = ({ coords }) => {
    // kafka.topic('active_drivers_location')
    //   .produce({
    //     "key": "DriverLocation", "value": JSON.stringify({
    //       'DriverToken': token,
    //       'OrderId': cookedOrdersData?.cookedOrders.id,
    //       'DriverLocation': {
    //         'lat': coords.lat,
    //         'lng': coords.lng
    //       }
    //     })
    //   });
    setDriverCoords({ lat: coords.latitude, lng: coords.longitude })
  };

  const { data: orderInProgress, refetch } = useQuery(GET_ORDER_INPROGRESS, 
  {
    client: request 
  });


  useEffect(() => {
    console.log("ORDERINPROGRESS: ", orderInProgress?.getOrderDriver?.order);
    
    if (orderInProgress?.getOrderDriver?.ok) {
      dispatch(SetOrderSelected(orderInProgress?.getOrderDriver?.order))
    }
  }, [orderInProgress, dispatch]);


  // if (locationId) {
  //   editLocationMutation({
  //     variables: {
  //       input: {
  //         locationId,
  //         currentLocation: {
  //           lat: driverCoords.lat,
  //           lng: driverCoords.lng
  //         }
  //       }
  //     }
  //   })
  // }

  const onError = (error: any) => {
    console.log(error)
  }

  useEffect(() => {
    const watchposition = navigator.geolocation.watchPosition(onSuccess, onError, { enableHighAccuracy: true });


    // let index = 0;
    // const limit = flightPlanCoordinates.length - 1;

    //     interval = setInterval(function () {
    //       if (index <= limit) {
    //         const current = flightPlanCoordinates[index];
    //         // editLocationMutation({
    //         //   variables: {
    //         //     input: {
    //         //       locationId,
    //         //       currentLocation: {
    //           //         lat: current.lat,
    //         //         lng: current.lng
    //         //       }
    //         //     }
    //         //   }
    //         // })
    //         kafka.topic('active_drivers_location')
    //         .produce({ 'key': 'DriverLocation', 'value': JSON.stringify({
    //           'lat': current.lat,
    //           'lng': current.lng
    //         }) }, 
    //         function(err,res){
    //                   console.log('err: ', err);
    //                   console.log('res: ', res);

    //                 });
    //         index++;
    //       }

    //       console.log("THIS WORKS");

    //     }, 5000);

    // socket.on('connect', () => {
    //   console.log('socket connected');
    // });
    // socket.on('joinedRoom', (room) => {
    //   console.log('socket join room');
    // });
    // socket.on('leftRoom', (room) => {
    //   console.log('socket left room');
    // });

    // const data = {
    //   token, 
    //   driverCoords
    // };
    // const {order} = orderInProgress;


    // socket.emit('joinRoom', { room: "drivers", data });
    // socket.emit('locationDriver', { data, room: "drivers" });

    return () => {
      navigator.geolocation.clearWatch(watchposition);
      if (interval)
        clearInterval(interval);

      // socket.close();

    }
  }, []);

  const { data: cookedOrdersData } = useSubscription<cookedOrders>(
    COOCKE_ORDERS_SUBSCRIPTION,
    {
      client: request
    }
  );

  const history = useHistory();

  const onCompleted = (data: takeOrder) => {
    if (data.takeOrder.ok) {
      dispatch(SetOrderSelected(order));
      refetch();
    }
  };

  const [takeOrderMutation] = useMutation<takeOrder, takeOrderVariables>(
    TAKE_ORDER_MUTATION,
    {
      onCompleted,
      client: request
    }
  );
  const [createLocationMutation] = useMutation(
    CREATE_LOCATION_MUTATION,
    {
      onCompleted: ({ createLocation }) => {
        locationId = createLocation.locationId;

        let index = 0;
        const limit = flightPlanCoordinates.length - 1;

        interval = setInterval(function () {
          if (index <= limit && locationId) {
            const current = flightPlanCoordinates[index];
            // editLocationMutation({
            //   variables: {
            //     input: {
            //       locationId,
            //       currentLocation: {
            //         lat: current.lat,
            //         lng: current.lng
            //       }
            //     }
            //   }
            // })
            // kafka.topic('').produce({
            //   'key': 'location', 'value': {
            //     lat: current.lat,
            //     lng: current.lng
            //   }, 'partition': 0
            // }, function (err, res) { });
            index++;
          }

          console.log("THIS WORKS");

        }, 5000);
      },
      client: request
    }
  );


  const triggerMutation = (orderId: number) => {
    createLocationMutation({
      variables: {
        input: {
          orderId: Number(orderId),
          currentLocation: {
            lat: driverCoords.lat,
            lng: driverCoords.lng
          }
        }
      },
      onCompleted: (data) => {
        if (data?.createLocation?.ok) {
          takeOrderMutation({
            variables: {
              input: {
                locationId: data?.createLocation?.locationId,
                id: Number(orderId),
              }
            }
          });
        } else {
          message.info('Ha ocurrido un error.')
        }
      }
    });
  };

  const [centerLocation] = useState({
    lat: 18.4861,
    lng: -69.9312
  });

  const [zoom] = useState(13);

  return (
    <div className="d-flex justify-content-center">
      <div
        className="overflow-hidden"
        style={{ width: window.innerWidth, height: window.innerHeight }}
      >
        <LoadScript 
          googleMapsApiKey={GOOGLE_MAPS_APIKEY}  
        >
        <GoogleMap
          mapContainerStyle={{ 
            width: window.innerWidth, 
            height: window.innerHeight
          }}
          center={{ lat: centerLocation.lat, lng: centerLocation.lng }}
          zoom={zoom}
          options={{
            gestureHandling: "greedy"
          }}
        >
          <>
            {orderInProgress?.getOrderDriver?.order?.restaurantLocation?.currentLocation && 
              orderInProgress?.getOrderDriver?.order?.locationEntity?.currentLocation && <DirectionsService
              options={{
                destination:{
                  lat: orderInProgress?.getOrderDriver?.order?.restaurantLocation?.currentLocation?.coordinates[1],
                  lng: orderInProgress?.getOrderDriver?.order?.restaurantLocation?.currentLocation?.coordinates[0]
                },
                travelMode: window.google.maps.TravelMode.DRIVING,
                origin: {
                  lat: orderInProgress?.getOrderDriver?.order?.locationEntity?.currentLocation?.coordinates[1],
                  lng: orderInProgress?.getOrderDriver?.order?.locationEntity?.currentLocation?.coordinates[0]
                }
              }}
              callback={directionsCallback}
            />}

            {directions === null && orderInProgress?.getOrderDriver?.order?.restaurantLocation?.currentLocation && <Marker position={{
              lat: orderInProgress?.getOrderDriver?.order?.restaurantLocation?.currentLocation?.coordinates[1],
              lng: orderInProgress?.getOrderDriver?.order?.restaurantLocation?.currentLocation?.coordinates[0]
            }} />}

            {directions === null && orderInProgress?.getOrderDriver?.order?.locationEntity?.currentLocation && <Marker position={{
              lat: orderInProgress?.getOrderDriver?.order?.locationEntity?.currentLocation?.coordinates[1],
              lng: orderInProgress?.getOrderDriver?.order?.locationEntity?.currentLocation?.coordinates[0]
            }} />}


            {
              directions !== null && (
                <DirectionsRenderer
                  options={{
                    directions: directions
                  }}
                  onLoad={directionsRenderer => {
                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                  }}
                  onUnmount={directionsRenderer => {
                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                  }}
                />
              )
            }

            </>
        </GoogleMap>
        </LoadScript>
      </div>
      <div className="overflow-hidden"
        style={{ width: window.innerWidth, height: window.innerHeight }}>
        {cookedOrdersData?.cookedOrders?.restaurant && orderInProgress?.getOrderDriver?.ok === false ? (
          <div className="fixed" style={{bottom: 20}}>
            <div className='flex-col px-8 py-6 bg-white shado-lg'>
              <h1 className="text-3xl font-medium text-center">
                New Cooked Order
              </h1>
              <h1 className="my-3 text-3xl font-medium text-center">
                Pick it up soon @{' '}
                {cookedOrdersData?.cookedOrders.restaurant?.name}
              </h1>
              <button
                onClick={() => {
                  setOrder(cookedOrdersData?.cookedOrders);
                  triggerMutation(cookedOrdersData?.cookedOrders.id);
                }}
                className="block w-full mt-5 text-center btn"
              >
                Accept Challenge
              </button>
            </div>
          </div>
        ) : orderInProgress?.getOrderDriver?.order?.status === OrderStatus.PickedUp ? (
          <TabsView />
        ) : (
          <div className="fixed bg-white h-80 shado-lg modal-search-driver">
            <h1 className="text-3xl font-medium text-center">No Orders yet</h1>
          </div>
        )
        }
      </div>
    </div>
  );
};
