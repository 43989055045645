import { gql, useQuery } from '@apollo/client';
import clientAuth from "../clients/auth";
export const ME_QUERY = gql`
  query meQuery {
    GetUser {
      user {
        id
        email
        role
        username
      }
    }
  }
`;

export const useMe = () => {
  return useQuery(ME_QUERY, {
    client: clientAuth
  });
};
