import { gql } from '@apollo/client';

export const PENDING_ORDERS_SUBSCRIPTION = gql`
  subscription pendingOrders {
    pendingOrders {
      id
    }
  }
`;

export const REMOVE_MY_RESTAURANT_QUERY = gql`
  mutation deleteRestaurant($input: DeleteRestaurantInput!) {
    deleteRestaurant(input: $input) {
      ok
      error
    }
  }
`;

export const MY_RESTAURANT_QUERY = gql`
  query myRestaurant($input: MyRestaurantInput!) {
    myRestaurant(input: $input) {
      ok
      error
      restaurant {
        id
        name
        coverImg
        category {
          name
        }
        address
        isPromoted
        menu {
          id
          Title
          Price
          Description
          Photo
          coverImg
        }
      }
    }
  }
`;


export const MY_DISH_QUERY = gql`
  query myDish($input: MyDishInput!) {
    myDish(input: $input) {
      ok
      error
      dish {
        id
        Title
        Price
        Description
        coverImg
        Category {
          name
        }
      }
    }
  }
`;