import React, { useEffect } from 'react';
import { gql, useQuery, useSubscription, useMutation, ApolloError } from '@apollo/client';
import { Dish } from 'components/dish';
import LogoSmartFood from 'assets/images/logo.png';

import {
  FULL_ORDER_FRAGMENT,
} from 'fragments';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  myRestaurant,
  myRestaurantVariables,
} from '__generated__/myRestaurant';
import { message, Popconfirm } from 'antd';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryPie
} from 'victory';
import foodClient from "clients/food";
import { pendingOrders } from '__generated__/pendingOrders';
import request from 'clients/request';
import { MY_RESTAURANT_QUERY, PENDING_ORDERS_SUBSCRIPTION, REMOVE_MY_RESTAURANT_QUERY } from './graphql';

interface IParams {
  id: string;
}

export const MyRestaurant = () => {
  const { id } = useParams<IParams>();
  const history = useHistory();

  const { data } = useQuery<myRestaurant, myRestaurantVariables>(
    MY_RESTAURANT_QUERY,
    {
      variables: {
        input: {
          id: +id,
        },
      },
      client: foodClient
    }
  );

  const [
    deleteRestaurantMutation,
    { loading },
  ] = useMutation(
    REMOVE_MY_RESTAURANT_QUERY,
    {
      onCompleted: () => {

      },
      onError: (err: ApolloError) => {
        message.error(err.message);
      },
      client: foodClient
    }
  );

  const { data: subscriptionData } = useSubscription<pendingOrders>(
    PENDING_ORDERS_SUBSCRIPTION,
    {
      client: request
    }
  );


  useEffect(() => {
    if (subscriptionData?.pendingOrders?.id) {
      history.push(`/orders/${subscriptionData.pendingOrders?.id}`);
    }
  }, [subscriptionData]);

  const onCompleted = () => {
    message.success('Restaurant deleted successfully.')
    history.goBack();
  }

  const RemoveRestaurant = () => {
    deleteRestaurantMutation({
      variables: {
        input: { restaurantId: Number(id) },
      },
      onCompleted,
      onError: () => {
        // setUploading(false);
      }
    });
  }

  return (
    <div>
      <div
        className="bg-gray-700 bg-center bg-cover py-28"
        style={{
          backgroundImage: `url(${data?.myRestaurant.restaurant?.coverImg || LogoSmartFood})`
        }}
      ></div>
      <div className="container mt-10">
        <h2 className="mb-10 text-4xl font-medium">
          {data?.myRestaurant.restaurant?.name || 'Loading...'}
        </h2>
        <div className="flex">
          <Link
            to={`/restaurants/${id}/add-dish`}
            className="px-10 py-3 mr-8 text-white bg-gray-800"
          >
            Add Dish &rarr;
          </Link>
          <Link to={``} className="px-10 py-3 mr-6 text-white y-3 bg-lime-700">
            Buy Promotion &rarr;
          </Link>

          <Link
            to={`/edit-restaurant/${id}`}
            className="px-10 py-3 mr-8 text-white bg-gray-800"
          >
            Edit

          </Link>

          <Popconfirm
            title="Are you sure to delete this restaurant with all its menu?"
            onConfirm={RemoveRestaurant}
            okText="Yes"
            okButtonProps={{
              id: 'OkleaveCall'
            }}
            cancelButtonProps={{
              id: 'CancelleaveCall'
            }}
            cancelText="No"
          >
            <Link
              to={``}
              className="px-10 py-3 mr-8 text-white bg-gray-800"
            >
              Remove

            </Link>
          </Popconfirm>

        </div>
        <div className="my-10">
          {data?.myRestaurant.restaurant?.menu.length === 0 ? (
            <h4>Please add a dish</h4>
          ) : (
            <div className="grid mt-16 md:grid-cols-3 gap-x-5 gap-y-10">
              {data?.myRestaurant.restaurant?.menu.map((dish, index) => (
                <Dish
                  key={index}
                  name={dish.Title}
                  coverImg={dish.coverImg}
                  description={dish.Description}
                  price={dish.Price}
                  restaurantId={id}
                  id={dish.id}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
