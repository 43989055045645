import React from 'react';
import { useMutation } from '@apollo/client';
import { ORDER_DELIVERED_MUTATION } from 'graphql/mutation';
import { takeOrder, takeOrderVariables } from '__generated__/takeOrder';
import request from 'clients/request';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SetOrderSelected } from "appRedux/actions";
import { IRootState } from 'interfaces/IRootState';

const Info = () =>  {
    const history = useHistory();
    const dispatch = useDispatch();
    const { order } = useSelector((state: IRootState) => state.order);

    const onCompleted = (data) => {
        debugger
        if (data.orderDelivered.ok) {
            dispatch(SetOrderSelected(null))
            history.push('/home')
        }
    }

    const [orderDeliveredMutation] = useMutation(
        ORDER_DELIVERED_MUTATION,
        {
          onCompleted,
          client: request
        }
      );

    const orderDelivered = () => {
        debugger
        if (order && order.id) {
            orderDeliveredMutation({
                variables: {
                    input: {
                        id: Number(order.id),
                    },
                }
            });
        }
    }

    return (
        <div>
                <strong>Estado del pedido: </strong><p>{order?.status}</p>
                <strong>Cliente: </strong><p>{order?.customer?.username} {`<${order?.customer?.email}>`}</p>
                {order?.driver && <>
                    <strong>Delivery: </strong>
                    <p>{order?.driver?.username} {`<${order?.driver?.email}>`}</p>
                </>} 
                <strong>Restaurante: </strong><p>{order?.restaurant?.name}</p>
        </div>
    );
}

export default Info;