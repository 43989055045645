import { gql, useMutation, useQuery } from '@apollo/client';
import { FULL_ORDER_FRAGMENT } from 'fragments';
import { useMe } from 'hooks/useMe';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router';
import { editOrder, editOrderVariables } from '__generated__/editOrder';
import { getOrder, getOrderVariables } from '__generated__/getOrder';
import { OrderStatus, UserRole } from '__generated__/globalTypes';
import { orderUpdates } from '__generated__/orderUpdates';
import IntlMessages from "utils/IntlMessages";
import { IParams } from 'interfaces/IParams';
import { GET_ORDER } from 'appRedux/querys/getorder';
import { ORDER_SUBSCRIPTION } from 'appRedux/subscriptions/order';
import food from 'clients/food';
import request from 'clients/request';

const EDIT_ORDER = gql`
  mutation editOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      ok
      error
    }
  }
`;


export const Order = () => {
  const params = useParams<IParams>();
  const { data: userData } = useMe();
  const [editOrderMutation] = useMutation<editOrder, editOrderVariables>(
    EDIT_ORDER,
    {
      client: request
    }
  );
  const { data, subscribeToMore } = useQuery<getOrder, getOrderVariables>(
    GET_ORDER,
    {
      variables: {
        input: {
          id: +params.id,
        },
      },
      client: request
    }
  );
  const history = useHistory();

  useEffect(() => {;
    
    if (data?.getOrder?.ok) {
      subscribeToMore({
        document: ORDER_SUBSCRIPTION,
        variables: {
          input: {
            id: +params.id,
          },
        },
        updateQuery: (
          prev,
          {
            subscriptionData: { data },
          }: { subscriptionData: { data } }
        ) => {
          if (!data) return prev;
          return {
            getOrder: {
              ...prev.getOrder,
              order: {
                ...data.orderUpdates,
              },
            },
          };
        },
      });
    }
  }, [data]);

  console.log(data?.getOrder);


  const onButtonClick = (newStatus: OrderStatus) => {
    editOrderMutation({
      variables: {
        input: {
          id: +params.id,
          status: newStatus,
        },
      },
    }).then(() => {
      history.push("/inProgress")
    });
  };

  return (
    <div className="container flex justify-center mt-6">
      <Helmet>
        <title>Order #{params.id} | SMARTFOOD</title>
      </Helmet>
      <div className="flex flex-col justify-center w-full max-w-screen-sm border border-gray-800">
        <h4 className="w-full py-5 text-xl text-center bg-gray-800 text-hite">
          Order #{params.id}
        </h4>
        <h5 className="p-5 pt-10 text-3xl text-center">
          ${data?.getOrder.order?.total}
        </h5>

        <div className="grid gap-6 p-5 text-xl">
          <div className="pt-5 border-t border-gra-y-700">
            Prepared By:{' '}
            <span className="font-medium">
              {data?.getOrder.order?.restaurant?.name}
            </span>
          </div>
          <div className="pt-5 border-t border-gray-700 ">
            Deliver To:{' '}
            <span className="font-medium">
              {data?.getOrder.order?.customer?.email}
            </span>
          </div>
          <div className="py-5 border-t border-b border-gray-700">
            Driver:{' '}
            <span className="font-medium">
              {data?.getOrder.order?.driver?.email || 'Not yet.'}
            </span>
          </div>
          {userData?.GetUser.user.role === UserRole.Client && (
            <span className="mt-5 mb-3 text-2xl text-center text-lime-600">
              Order Status: {data?.getOrder.order?.status}
            </span>
          )}

          {userData?.GetUser.user.role === UserRole.Restaurant && (
            <>
              {data?.getOrder.order?.status === OrderStatus.Pending && (
                <div className='flex'>
                  <button
                    className="btn d-flex justify-content-center align-items-center"
                    onClick={() => onButtonClick(OrderStatus.PickedUp)}
                  >
                    <IntlMessages id="app.ReadyForPickup" />
                  </button>
                  <button
                    className="btn d-flex justify-content-center align-items-center"
                    onClick={() => onButtonClick(OrderStatus.Cooking)}
                  >
                    <IntlMessages id="app.confirm" />
                  </button>
                </div>
              )}
              {data?.getOrder.order?.status === OrderStatus.Cooking && (
                <button
                  className="btn"
                  onClick={() => onButtonClick(OrderStatus.Cooked)}
                >
                  Order Cooked
                </button>
              )}

              {data?.getOrder.order?.status !== OrderStatus.Cooking &&
                data?.getOrder.order?.status !== OrderStatus.Pending && (
                  <span className="font-medium">
                    Status {data?.getOrder.order?.status}
                  </span>
                )}
            </>
          )}

          {userData?.GetUser.user.role === UserRole.Delivery && (
            <>
              {data?.getOrder.order?.status === OrderStatus.Cooked && (
                <button
                  className="btn"
                  onClick={() => onButtonClick(OrderStatus.PickedUp)}
                >
                  Picked UP
                </button>
              )}
              {data?.getOrder.order?.status === OrderStatus.PickedUp && (
                <button
                  className="btn"
                  onClick={() => onButtonClick(OrderStatus.Delivered)}
                >
                  Order Delivered
                </button>
              )}
            </>
          )}

          {data?.getOrder.order?.status === OrderStatus.Delivered && (
            <span className="font-medium">Thank you for using SMARTFOOD</span>
          )}
        </div>
      </div>
    </div>
  );
};
