import { InMemoryCache, ApolloClient, ApolloLink, makeVar, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";

import { LOCALSTORAGE_TOKEN } from 'const';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
export const authTokenVar = makeVar(token);



const authLink = setContext((_, { headers }) => {
  const token = authTokenVar() || localStorage.getItem(LOCALSTORAGE_TOKEN)
  return {
    headers: {
      ...headers,
      'x-jwt': token,
    },
  };
});


const errorLink = onError(({ graphQLErrors, networkError, forward }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    });
  if (networkError)
    console.log(`[Network error]: ${networkError}`);

});

export default new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: ApolloLink.from([errorLink, authLink, new HttpLink({ uri: `${process.env.REACT_APP_DAILYFOOD_API_AUTH_HTTP}/graphql` })]),
  connectToDevTools: true
});
