import type { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import axios from 'axios';

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLt2M;
};

export async function NameAddress({ latitude, longitude }) {
  try {
      let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB3BcTM780wUx64-OC6vjKs-M1KlqEOdqE`
      let response = await axios({
        method: "get",
        url
      });
      let name_address = response?.data?.results[0].formatted_address;
      return name_address;
  } catch (err) {
      console.warn(err)
  }
}