import { gql } from "@apollo/client";
import { FULL_ORDER_FRAGMENT } from "fragments";

export const GET_ORDER = gql`
  query getOrder($input: GetOrderInput!) {
    getOrder(input: $input) {
      ok
      error
      order {
        id
        status
        total
        driver {
          username
          email
        }
        driverLocation {
          currentLocation
        }
        restaurantLocation {
          currentLocation
        }
        locationEntity {
          currentLocation
        }
        customer {
          username
          email
        }
        restaurant {
          name
        }
      }
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;