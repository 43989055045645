import React, { useEffect, useState } from 'react';
import { useMe } from 'hooks/useMe';
import { NotFound } from 'pages/404';
import { DashboardOwner } from 'pages/owner/dashboard';
import { Order } from 'pages/order';
import { ConfirmEmail } from 'pages/user/confirm-email';
import { EditProfile } from 'pages/user/edit-profile';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserRole } from '__generated__/globalTypes';
import { DashboardCommon } from 'pages/common/dashboard';
import { Home } from 'pages/home';
import { useHistory } from 'react-router-dom';
import { NewOrderComponent } from 'pages/restaurant/NewOrderComponent';


const commonRoutes = [
  {
    path: '/home',
    component: <Home />
  },
  {
    path: '/confirm',
    component: <ConfirmEmail />,
  },
  {
    path: '/edit-profile',
    component: <EditProfile />,
  },
  {
    path: '/orders/:id',
    component: <Order />,
  },
];

const restaurantRoutes = [
  {
    path: '/',
    component: <DashboardCommon />,
  }
];

const driverRoutes = [
  {
    path: '/',
    component: <DashboardCommon />,
  }
];

const ownerRoutes = [
  {
    path: '/',
    component: <DashboardOwner />,
  },
];

const mp3 = new Audio("https://creatorassets.com/cdn/notification-sound-effects/sound-5.mp3");

export const LoggedInRouter = () => {
  const history = useHistory();

  const { data, loading, error } = useMe();

  useEffect(() => {
    if (loading === false && data?.GetUser?.user) {
      history.push('/home');
    }
    if (loading === false && !data?.GetUser?.user) {
      localStorage.clear();
      history.push('/');
      window.location.reload();
    }
  }, [loading, data, error, history]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      history.push('/home');
    }
  }, []);

  if (!data || loading || error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="text-xl font-medium tracking-wide">Loading...</span>
      </div>
    );
  }
  return (
    <>
      {data?.GetUser?.user?.role === UserRole.Restaurant &&
        <NewOrderComponent mp3={mp3} />
      }
      <Router>
        <Switch>
          {data?.GetUser?.user?.role === UserRole.Restaurant &&
            restaurantRoutes.map((route) => (
              <Route key={route.path} path={route.path}>
                {route.component}
              </Route>
            ))}

          {data?.GetUser?.user?.role === UserRole.Owner &&
            ownerRoutes.map((route) => (
              <Route key={route.path} path={route.path}>
                {route.component}
              </Route>
            ))}

          {data?.GetUser?.user?.role === UserRole.Delivery &&
            driverRoutes.map((route) => (
              <Route key={route.path} path={route.path}>
                {route.component}
              </Route>
            ))}

          {commonRoutes.map((route) => (
            <Route key={route.path} path={route.path}>
              {route.component}
            </Route>
          ))}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
