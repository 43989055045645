import React from 'react';
import { useSelector } from 'react-redux';
import { useMe } from 'hooks/useMe';
import { IRootState } from 'interfaces/IRootState';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from "antd";
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import {
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "constants/ThemeSetting";
import HorizontalDefault from "components/Topbar/HorizontalDefault/index";
import HorizontalDark from "components/Topbar/HorizontalDark/index";
import InsideHeader from "components/Topbar/InsideHeader/index";
import AboveHeader from "components/Topbar/AboveHeader/index";
import BelowHeader from "components/Topbar/BelowHeader/index";
import NoHeaderNotification from "components/Topbar/NoHeaderNotification/index";
import { NotFound } from 'pages/404';
import { commonRoutes, ownerRoutes } from 'utils/routes';
import SidebarContent from "components/Sidebar/SidebarContent";

const { Content } = Layout;


export const DashboardOwner = () => {

    const { navStyle } = useSelector((state: IRootState) => state.settings);

    const { data, loading, error } = useMe();

    if (!data || loading || error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <span className="text-xl font-medium tracking-wide">Loading...</span>
            </div>
        );
    }
    
    return (
        <Router>
            <Layout className="gx-app-layout">
                <Sidebar SidebarContent={SidebarContent} />
                <Layout>
                    <Topbar />
                    <Content className={`gx-layout-content gx-container-wrap`}>
                        <Switch>

                            {ownerRoutes.map((route) => (
                                <Route key={route.path} path={route.path}>
                                    <route.component />
                                </Route>
                            ))}

                            {commonRoutes.map((route) => (
                                <Route key={route.path} path={route.path}>
                                    <route.component />
                                </Route>
                            ))}
                            <Route>
                                <NotFound />
                            </Route>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
};
