import React from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { authTokenVar, isLoggedInVar } from 'apolloConfig';
import { Button } from 'components/button';
import { FormError } from 'components/form-error';
import { LOCALSTORAGE_TOKEN } from 'const';
import smartfoodLogo from 'images/logo.png';
import { message } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import auth from '../clients/auth';

export const LOGIN_MUTATION = gql`
  mutation loginMutation($password: String!, $email: String!) {
    signIn(password: $password, email: $email) {
      email
      accessToken
      role
    }
  }
`;

interface ILoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({
    mode: 'onChange',
  });

  const onCompleted = ({ signIn }) => {
    const {
      accessToken
    } = signIn;
    if (accessToken && accessToken.length > 0) {
      localStorage.setItem(LOCALSTORAGE_TOKEN, accessToken);
      authTokenVar(accessToken);
      isLoggedInVar(true);
    }
  };

  const [loginMutation, { data: loginMutationResult, loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted,
    onError: (err: ApolloError) => {
      message.error(err.message);
    },
    client: auth
  });

  const onSubmit = () => {
    if (!loading) {
      const { email, password } = getValues();
      loginMutation({
        variables: { email, password },
      });
    }
  };

  return (
    <div className="flex flex-col items-center h-screen mt:10 lg:mt-28">
      <Helmet>
        <title>Login | SMARTFOOD</title>
      </Helmet>
      <div className="flex flex-col items-center w-full max-w-screen-sm px-5">
        <img src={smartfoodLogo} className="mb-10 w-52" alt="smartfoodlogo" />
        <h4 className="w-full mb-5 text-3xl font-medium text-left">
          Welcome
        </h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid w-full gap-3 mt-5 mb-5"
        >
          <input
            {...register('email', {
              required: 'Email is required',
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            type="email"
            placeholder="Email"
            className="input "
          />

          {errors.email?.message && (
            <FormError errorMessage={errors.email?.message[0]} />
          )}

          {errors.email?.type === 'pattern' && (
            <FormError errorMessage={'Please enter a valid email'} />
          )}

          <input
            {...register('password', {
              required: 'Password is required',
              minLength: 4,
            })}
            type="password"
            placeholder="Password"
            className="input"
          />

          {errors.password?.message && (
            <FormError errorMessage={errors.password?.message[0]} />
          )}

          {errors.password?.type === 'minLength' && (
            <FormError errorMessage="Password must be more than 4 chars." />
          )}

          <Button canClick={isValid} loading={loading} actionText={'Log in'} />
          {loginMutationResult?.signIn.error && (
            <FormError errorMessage={loginMutationResult?.signIn.error} />
          )}
        </form>
        {/* <div>
          New to SMARTFOOD ?{' '}
          <Link to="/create-account" className="text-lime-600 hover:underline">
            Create an Account
          </Link>
        </div> */}
      </div>
    </div>
  );
};
