import { gql } from '@apollo/client/core';
// import { CATEGORY_FRAGMENT } from '../../fragments';

export const GetCategories = gql`
    query allCategories {
      allCategories {
        categories {
          id
          name
          coverImg
          description
        }
      }
    }
`;


