import { gql, useQuery } from '@apollo/client';
import { List, Typography, Card, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IntlMessages from 'utils/IntlMessages';
import { OrderStatus } from '__generated__/globalTypes';
import { format } from 'date-fns';
import "./inProgress.css";
import { useHistory } from 'react-router-dom';
import clientFood from "../../clients/food";
import request from 'clients/request';
import Logo from 'assets/images/logo.png';

const { Title } = Typography;

export const ORDERS_BY_RESTAURANT = gql`
  query Orders($input: GetOrdersInput!) {
    getOrders(input: $input) {
        ok
        error
        totalResults
        orders {
            id
            status
            restaurant {
                name
                address
                coverImg
            }
            driver {
                email
                username
            }
            customer {
                username
                email
            }

        }
    }
  }
`;

export const Delivery = () => {

    const history = useHistory();

    const [page,setPage] = useState(0)


    const { data, loading, error } = useQuery(
        ORDERS_BY_RESTAURANT,
        {
            variables: {
                input: {
                    skip: (page - 1) > 0 ? (page - 1) * 10 : 0,
                    status: [OrderStatus.PickedUp, OrderStatus.Cooked],
                }
            },
            // fetchPolicy: "cache-and-network"
            client: request
        }
    );

    const onButtonClick = (data) => {
        history.push(`/delivery/${data.id}`);
    };


    if (!data || loading || error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <span className="text-xl font-medium tracking-wide">Loading...</span>
            </div>
        );
    }

    console.log(data?.getOrders?.orders);
    

    return (
        <List
            header={<Title level={4}>
                <IntlMessages id="sidebar.delivery" />
            </Title>}
            bordered
            pagination={{
                pageSize: 10,
                current: page,
                total: data?.getOrders?.totalResults,
                onChange: (page) => {
                    setPage(page);
                }
            }}
            dataSource={data?.getOrders?.orders ? data.getOrders.orders : []}
            renderItem={(item: any) => (
                <List.Item>
                    <Card style={{ width: 500 }}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <img width={100} src={item?.restaurant?.coverImg || Logo} alt="" />
                            </div>
                            <div className="flex justify-between w-80">
                                <div className='flex flex-col'>
                                    <strong className="text-capitalize">Status: {item?.status}</strong>
                                    <strong className="text-capitalize">Customer: {item?.customer?.username}</strong>
                                    <strong className="text-capitalize">Restaurant: {item?.restaurant?.name}</strong>
                                    {item?.driver && <strong className="text-capitalize">Driver: {item?.driver?.username}</strong>}
                                    <p>Order: #{item.id}</p>
                                </div>
                                <div>
                                    {item && item.createdAt && <strong>{ format(new Date(item.createdAt), 'p')}</strong>}
                                    <Button onClick={() => onButtonClick(item)}
                                        style={{ width: 70, marginLeft: 20, marginBottom: 0 }}>
                                        <IntlMessages id="sidebar.view" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </List.Item>
            )}
        />
    )
}
