import React, { useState, useEffect } from 'react';
import { DirectionsRenderer, GoogleMap, LoadScript, Marker, DirectionsService } from '@react-google-maps/api';
import { GOOGLE_MAPS_APIKEY } from 'const';
import IntlMessages from 'utils/IntlMessages';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faSpinner,
  faMapPin
} from '@fortawesome/free-solid-svg-icons';
import PlaceIcon from '@material-ui/icons/Place';
import { useDispatch, useSelector } from 'react-redux';
import "./deliveryMaps.css";
import { IRootState } from 'interfaces/IRootState';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { FULL_ORDER_FRAGMENT } from 'fragments';
import { useParams } from 'react-router';
import { IParams } from 'interfaces/IParams';
import { getOrder, getOrderVariables } from '__generated__/getOrder';
import { ORDER_SUBSCRIPTION } from 'appRedux/subscriptions/order';
import { orderUpdates } from '__generated__/orderUpdates';
import { OrderStatus } from '__generated__/globalTypes';
import TabsView from 'components/TabsView'
import request from 'clients/request';
import { GET_ORDER } from 'appRedux/querys/getorder';
import { SetOrderSelected } from 'appRedux/actions/Order';
import { EOrderStatus } from 'enums';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

library.add(
  faSpinner as any,
  faMapPin as any
);


const ORDER_UPDATES_SUBSCRIPTION = gql`
  subscription orderUpdates {
    orderUpdates {
      ...FullOrderParts
    }
  }
  ${FULL_ORDER_FRAGMENT}
`;

export const DeliveryMaps = () => {

  const [centerLocation, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 18.4861,
    lng: -69.9312
  });
  const [directions, setDirections] = useState<google.maps.DirectionsResult>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams<IParams>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, subscribeToMore, refetch } = useQuery<getOrder, getOrderVariables>(
    GET_ORDER,
    {
      variables: {
        input: {
          id: +params.id,
        },
      },
      client: request
    }
  );

  const directionsCallback = (response) => {
    if (directions === null || directions === undefined) {
      if (response?.status === 'OK') {
        setDirections(response);
      } else {
        console.log('response: ', response)
      }
    }
  }

  useEffect(() => {
  
    if (data?.getOrder?.order?.status === OrderStatus.Delivered) {
      setIsModalOpen(true);
      return 
    }

    if (data?.getOrder?.order?.restaurantLocation && data?.getOrder?.order?.locationEntity) {
      const pointA = data?.getOrder?.order?.restaurantLocation.currentLocation.coordinates;
      const pointB = data?.getOrder?.order?.locationEntity.currentLocation.coordinates;
      setCenter({
        lat: ((pointA[1] + pointB[1]) / 2.0),
        lng: ((pointA[0] + pointB[0]) / 2.0)
      });
    }
    

    if (data?.getOrder?.order?.status === OrderStatus.Pending) {
      refetch();
    }
    if (data?.getOrder?.ok) {
      dispatch(SetOrderSelected(data?.getOrder?.order))
      subscribeToMore({
        document: ORDER_SUBSCRIPTION,
        variables: {
          input: {
            id: +params.id,
          },
        },
        updateQuery: (
          prev,
          {
            subscriptionData: { data },
          }: { subscriptionData: { data } }
        ) => {
          if (!data) return prev;
          return {
            getOrder: {
              ...prev.getOrder,
              order: {
                ...data.orderUpdates,
              },
            },
          };
        },
      });
    }
  }, [data, subscribeToMore, dispatch, refetch, params.id]);

  const [zoom] = useState(13);

  const handleOk = () => {
    history.replace('/delivery');
  }

  return (
    <div className="d-flex justify-content-center">
    <Modal  title="Orden entregada al cliente" open={isModalOpen} 
            onOk={handleOk}
            afterClose={handleOk}
            onCancel={handleOk}
            footer={[
              <Button key="submit" type="primary" onClick={handleOk}>
              Ha seguir creciendo 🚀
            </Button>
            ]}>
      <div>
        Orden #{data?.getOrder?.order?.id} entregada al cliente {data?.getOrder?.order?.customer?.username}{`<${data?.getOrder?.order?.customer?.email}>`} por el delivery {data?.getOrder?.order?.driver?.username}{`<${data?.getOrder?.order?.driver?.email}>`}
      </div>
    </Modal>

      <div
        className="overflow-hidden"
        style={{ width: window.innerWidth, height: window.innerHeight }}
      >
        <LoadScript 
          googleMapsApiKey={GOOGLE_MAPS_APIKEY}  
        >
          <GoogleMap
            mapContainerStyle={{ 
              width: window.innerWidth, 
              height: window.innerHeight
            }}
            center={{ lat: centerLocation.lat, lng: centerLocation.lng }}
            zoom={zoom}
            options={{
              gestureHandling: "greedy"
            }}
          >
            <>
            { data?.getOrder?.order?.driverLocation?.currentLocation && 
              data?.getOrder?.order?.status === OrderStatus.PickedUp && 
              data?.getOrder?.order?.locationEntity?.currentLocation && <DirectionsService
              options={{
                destination:{
                  lat: data?.getOrder?.order?.driverLocation?.currentLocation?.coordinates[1],
                  lng: data?.getOrder?.order?.driverLocation?.currentLocation?.coordinates[0]
                },
                travelMode: window.google.maps.TravelMode.DRIVING,
                origin: {
                  lat: data?.getOrder?.order?.locationEntity?.currentLocation?.coordinates[1],
                  lng: data?.getOrder?.order?.locationEntity?.currentLocation?.coordinates[0]
                }
              }}
              callback={directionsCallback}
            />}

            {directions === null && data?.getOrder?.order?.restaurantLocation?.currentLocation && <Marker position={{
              lat: data?.getOrder?.order?.restaurantLocation?.currentLocation?.coordinates[1],
              lng: data?.getOrder?.order?.restaurantLocation?.currentLocation?.coordinates[0]
            }} />}

            {directions === null && data?.getOrder?.order?.locationEntity?.currentLocation && <Marker position={{
              lat: data?.getOrder?.order?.locationEntity?.currentLocation?.coordinates[1],
              lng: data?.getOrder?.order?.locationEntity?.currentLocation?.coordinates[0]
            }} />}

            {directions === null && data?.getOrder?.order?.driverLocation?.currentLocation && <Marker position={{
              lat: data?.getOrder?.order?.driverLocation?.currentLocation?.coordinates[1],
              lng: data?.getOrder?.order?.driverLocation?.currentLocation?.coordinates[0]
            }} />}

            {
              directions !== null && (
                <DirectionsRenderer
                  options={{
                    directions: directions
                  }}
                  onLoad={directionsRenderer => {
                    console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                  }}
                  onUnmount={directionsRenderer => {
                    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                  }}
                />
              )
            }

            </>
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="fixed" style={{bottom: 20}}>
        <div className='flex-col px-8 py-6 bg-white shado-lg'>
          {data?.getOrder?.order?.driver === null ? (
            <h1 className="text-3xl font-medium text-center">
              <IntlMessages id="app.searchingDelivery" />
              <FontAwesomeIcon icon={faSpinner as any} className="ml-2 text-xl spinner" />
            </h1>
          ) : data?.getOrder?.order?.status === OrderStatus.PickedUp && (
            <TabsView />
          )}
        </div>
      </div>
    </div>
  )
}


