import {
    SET_ORDER
  } from "constants/ActionTypes";
  const INIT_STATE = {
      order: null
  }
  
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case SET_ORDER: {
        return {
          ...state,
          order: action.payload,
        }
      }
  
      default:
        return state;
    }
  }
  