import React, { useEffect} from "react";
import { Menu, MenuProps, Tooltip } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "utils/CustomScrollbars";
import IntlMessages from "../../utils/IntlMessages";
import { useSelector } from "react-redux";
import { IRootState } from "interfaces/IRootState";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faClipboard,
  faUtensils,
  faShoppingBag,
  faUser,
  faTag,
  faChartArea,
  faBullhorn,
  faPercent,
  faBook,
  faBell,
  faHome,
  faHistory,
  faBookOpen,
  faCalendar
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarLogo from "components/Sidebar/SidebarLogo";
import { useMe } from 'hooks/useMe';
import "./sidebarContent.css";
import {
  BellOutlined,
  AreaChartOutlined,
  UserOutlined,
  HomeOutlined,
  HistoryOutlined
} from "@ant-design/icons";
import { UserRole } from "__generated__/globalTypes";
import { useQuery } from "@apollo/client";
import food from "clients/food";
import { GET_RESTAURANT_BY_USER } from "graphql/query";
const arr: any = [faClipboard,
  faUtensils,
  faShoppingBag,
  faUser,
  faTag,
  faChartArea,
  faBullhorn,
  faPercent,
  faBell,
  faHome,
  faHistory,
  faBook,
  faBookOpen,
  faCalendar]
library.add(
  ...arr
);
const MenuItemGroup = Menu.ItemGroup;
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const items: MenuItem[] = [
  getItem(<Link to="/home"><IntlMessages id="sidebar.home" /></Link>, '1',
    <Link to="/home">
      <FontAwesomeIcon icon={['fas', 'home']} />
    </Link>
  ),

  getItem(<Link to="/orders"><IntlMessages id="sidebar.orders" /></Link>, '2',
    <Link to="/orders">
      <FontAwesomeIcon icon={['fas', 'bell']} />
    </Link>
  ),

  getItem(<Link to="/inProgress"><IntlMessages id="sidebar.inProgress" /></Link>, '4',
    <Link to="/inProgress">
      <FontAwesomeIcon icon={['fas', 'utensils']} />
    </Link>
  ),

  getItem(<Link to="/delivery"><IntlMessages id="sidebar.delivery" /></Link>, '5',
    <Link to="/delivery">
      <FontAwesomeIcon icon={['fas', 'history']} />
    </Link>
  ),

  getItem(<Link to="/menu/"><IntlMessages id="sidebar.menu" /></Link>, '3',
    <Link to="/menu">
      <FontAwesomeIcon icon={['fas', 'book-open']} />
    </Link>
  ),

  getItem(<Link to="/schedule"><IntlMessages id="sidebar.schedule" /></Link>, '3',
    <Link to="/schedule">
      <FontAwesomeIcon icon={['fas', 'calendar']} />
    </Link>
  ),

  getItem(<Link to="/marketing"><IntlMessages id="sidebar.marketing" /></Link>, '6',
    <Link to="/marketing">
      <FontAwesomeIcon icon={['fas', 'bullhorn']} />
    </Link>
  ),

  getItem(<Link to="/discounts"><IntlMessages id="sidebar.discounts" /></Link>, '7',
    <Link to="/discounts">
      <FontAwesomeIcon icon={['fas', 'percent']} />
    </Link>
  ),
];

const routesDriver: MenuItem[] = [
  getItem(<Link to="/home"><IntlMessages id="sidebar.home" /></Link>, '1',
    <Link to="/home">
      <HomeOutlined />
    </Link>
  ),

  getItem(<Link to="/orders"><IntlMessages id="sidebar.orders" /></Link>, '2',
    <Link to="/orders">
      <BellOutlined size={40} />
    </Link>),

  getItem(<Link to="/history"><IntlMessages id="sidebar.history" /></Link>, '3',
  <Link to="/history">
    <HistoryOutlined size={40} />
  </Link>),


  getItem(<Link to="/analytics"><IntlMessages id="sidebar.analytics" /></Link>, '4',
    <Link to="/analytics">
      <AreaChartOutlined />
    </Link>)
];

const SidebarContentCommon = () => {

  let { pathname } = useSelector((state) => ({ pathname: '/' }));
  const { data } = useMe();

  const { data: restaurant } = useQuery(
    GET_RESTAURANT_BY_USER,
    {
      client: food
    }
  );

  useEffect(() => {
    console.log(restaurant?.findRestaurantByUser?.restaurant?.id)
  }, [restaurant])

  const items: MenuItem[] = [
    getItem(<Link to="/home"><IntlMessages id="sidebar.home" /></Link>, '1',
      <Link to="/home">
        <FontAwesomeIcon icon={['fas', 'home']} />
      </Link>
    ),
  
    getItem(<Link to="/orders"><IntlMessages id="sidebar.orders" /></Link>, '2',
      <Link to="/orders">
        <FontAwesomeIcon icon={['fas', 'bell']} />
      </Link>
    ),
  
    getItem(<Link to="/inProgress"><IntlMessages id="sidebar.inProgress" /></Link>, '4',
      <Link to="/inProgress">
        <FontAwesomeIcon icon={['fas', 'utensils']} />
      </Link>
    ),
  
    getItem(<Link to="/delivery"><IntlMessages id="sidebar.delivery" /></Link>, '5',
      <Link to="/delivery">
        <FontAwesomeIcon icon={['fas', 'history']} />
      </Link>
    ),
  
    getItem(<Link to={`/menu/${restaurant?.findRestaurantByUser?.restaurant?.id}`}><IntlMessages id="sidebar.menu" /></Link>, '3',
      <Link to={`/menu/${restaurant?.findRestaurantByUser?.restaurant?.id}`}>
        <FontAwesomeIcon icon={['fas', 'book-open']} />
      </Link>
    ),
  
    getItem(<Link to="/schedule"><IntlMessages id="sidebar.schedule" /></Link>, '3',
      <Link to="/schedule">
        <FontAwesomeIcon icon={['fas', 'calendar']} />
      </Link>
    ),
  
    getItem(<Link to="/marketing"><IntlMessages id="sidebar.marketing" /></Link>, '6',
      <Link to="/marketing">
        <FontAwesomeIcon icon={['fas', 'bullhorn']} />
      </Link>
    ),
  
    getItem(<Link to="/discounts"><IntlMessages id="sidebar.discounts" /></Link>, '7',
      <Link to="/discounts">
        <FontAwesomeIcon icon={['fas', 'percent']} />
      </Link>
    ),
  ];
  
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={
              "light"
            }
            mode="inline"
            items={data?.GetUser?.user?.role === UserRole.Delivery ? routesDriver : items}>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContentCommon.propTypes = {};
export default SidebarContentCommon;

