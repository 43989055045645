import React, { useEffect, useState } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { useHistory } from 'react-router';

import { pendingOrders } from '__generated__/pendingOrders';

import foodLogo from 'assets/images/foodopen.png';
import { Helmet } from 'react-helmet-async';
import "./orders.css";
import request from 'clients/request';
import { PENDING_ORDERS_SUBSCRIPTION } from './graphql';
import { Typography } from 'antd';
import { NewOrderComponent } from './NewOrderComponent';
const { Title } = Typography;

export const Orders = () => {

  return (
    <>
      <div className="container mt-10 h-100 d-flex justify-content-center flex-column align-items-center">
        <Helmet>
          <title>Orders | SMARTFOOD</title>
        </Helmet>
        <img src={foodLogo} width={150} alt="foodLogo" />
        <Title level={3}>Open</Title>
        <p >Closes at 10:00PM</p>
      </div>
    </>
  );
};
