import { gql } from "@apollo/client";

export const ORDER_DELIVERED_MUTATION = gql`
  mutation orderDelivered($input: TakeOrderInput!) {
    orderDelivered(input: $input) {
      ok
      id
      error
    }
  }
`;

export const EDIT_ORDER = gql`
  mutation editOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      ok
      error
    }
  }
`;

export const EDIT_PROFILE_MUTATION = gql`
  mutation editProfile($input: EditProfileInput!) {
    editProfile(input: $input) {
      ok
      error
    }
  }
`;

export const SAVE_SCHEDULE_MUTATION = gql`
  mutation saveSchedule($input: CreateScheduleInput!) {
    saveSchedule(input: $input) {
      ok
      error
    }
  }
`;
