import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from "react-router-dom";

import { client } from 'apolloConfig';
import { ApolloProvider } from '@apollo/client';

import "assets/vendors/style";
import 'bootstrap/dist/css/bootstrap.css';
import 'styles/styles.css';


import configureStore, { history } from './appRedux/store';
import App from "./containers/App";

const store = configureStore({});

const NextApp = (): JSX.Element =>
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route path="/" component={App} />
      </ConnectedRouter>
    </Provider>;
  </ApolloProvider>


export default NextApp;
