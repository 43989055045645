import React from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { FormError } from 'components/form-error';
import smartfoodLogo from 'images/logo.png';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { UserRole } from '__generated__/globalTypes';
import { message } from 'antd';
import auth from '../clients/auth';

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation createAccountMutation($password: String!, $email: String!, $role: String!) {
    signUp(password: $password, email: $email, role: $role) {
      email
      accessToken
      expiresIn
      role
    }
  }
`;

interface ICreateAccountForm {
  email: string;
  password: string;
  role: UserRole;
}

export const CreateAccount = () => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICreateAccountForm>({
    mode: 'onChange',
    defaultValues: {
      role: UserRole.Client,
    },
  });

  const history = useHistory();
  const onCompleted = ({ signUp }) => {
    const {
      accessToken
    } = signUp;

    if (accessToken && accessToken.length > 0) {
      message.info('Account Created! Log in now!');
      history.push('/home');
    }
  };

  const [
    createAccountMutation,
    { loading, data: createAccountMutationResult },
  ] = useMutation(
    CREATE_ACCOUNT_MUTATION,
    {
      onCompleted,
      onError: (err: ApolloError) => {
        message.error(err.message);
      },
      client: auth
    }
  );

  const onSubmit = () => {
    if (!loading) {
      const { email, password, role } = getValues();
      createAccountMutation({
        variables: {
          email,
          password,
          role
        },
      }).catch(err => {
        alert(err.message);
      });
    }
  };

  return (
    <div className="flex flex-col items-center h-screen mt:10 lg:mt-28">
      <Helmet>
        <title>Create Account | SMARTFOOD</title>
      </Helmet>
      <div className="flex flex-col items-center w-full max-w-screen-sm px-5">
        <img src={smartfoodLogo} className="mb-10 w-52" alt="smartfoodLogo" />
        <h4 className="w-full mb-5 text-3xl font-medium text-left">
          Let's get started
        </h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid w-full gap-3 mt-5 mb-5"
        >
          <input
            {...register('email', {
              required: 'Email is required',
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            type="email"
            placeholder="Email"
            className="input "
          />

          {errors.email?.message && (
            <FormError errorMessage={errors.email?.message[0]} />
          )}

          {errors.email?.type === 'pattern' && (
            <FormError errorMessage={'Please enter a valid email'} />
          )}

          <input
            {...register('password', {
              required: 'Password is required',
              minLength: 4,
            })}
            type="password"
            placeholder="Password"
            className="input"
          />

          {errors.password?.message && (
            <FormError errorMessage={errors.password?.message[0]} />
          )}

          {errors.password?.type === 'minLength' && (
            <FormError errorMessage="Password must be more than 4 chars." />
          )}

          <select
            className="input"
            {...register('role', {
              required: true,
            })}
          >
            {Object.keys(UserRole).map((role, index) => (
              <option key={index}>{role}</option>
            ))}
          </select>

          <Button
            canClick={isValid}
            loading={loading}
            actionText={'Create Account'}
          />
          {createAccountMutationResult?.signUp.error && (
            <FormError
              errorMessage={createAccountMutationResult.signUp.error}
            />
          )}
        </form>
        <div>
          Already have an account?{' '}
          <Link to="/" className="text-lime-600 hover:underline">
            Log in now
          </Link>
        </div>
      </div>
    </div>
  );
};
