import React from "react";
import { Link } from "react-router-dom";

import CustomScrollbars from "utils/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../utils/IntlMessages";
import { useSelector } from "react-redux";
import { IRootState } from "interfaces/IRootState";
import { library } from '@fortawesome/fontawesome-svg-core';
import { Menu, MenuProps, Tooltip } from "antd";
import {
  faUtensils,
  faCreditCard,
  faHome,
  faStar,
  faUmbrellaBeach,
  faBullhorn,
  faStopwatch,
  faUsers,
  faFolder,
  faCog,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMe } from 'hooks/useMe';
const arr: any = [faUtensils,
  faCreditCard,
  faHome,
  faStar,
  faUmbrellaBeach,
  faBullhorn,
  faStopwatch,
  faUsers,
  faFolder,
  faCog,
  faQuestionCircle]
library.add(
  ...arr
);

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = () => {

  let { pathname } = useSelector((state) => ({ pathname: '/' }));

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {

      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const items: MenuItem[] = [
    getItem(<Link to="/home"><IntlMessages id="sidebar.home" /></Link>, '1',
      <Link to="/home">
        <FontAwesomeIcon icon={['fas', 'home']} />
      </Link>
    ),

    getItem(<Link to="/feedback"><IntlMessages id="sidebar.feedback" /></Link>, '2',
      <Link to="/feedback">
        <FontAwesomeIcon icon={['fas', 'star']} />
      </Link>
    ),

    getItem(<Link to="/payments"><IntlMessages id="sidebar.payments" /></Link>, '3',
      <Link to="/payments">
        <FontAwesomeIcon icon={['fas', 'credit-card']} />
      </Link>
    ),

    getItem(<Link to="/restaurants"><IntlMessages id="sidebar.restaurants" /></Link>, '4',
      <Link to="/restaurants">
        <FontAwesomeIcon icon={['fas', 'utensils']} />
      </Link>
    ),

    getItem(<Link to="/holiday"><IntlMessages id="sidebar.holiday" /></Link>, '5',
      <Link to="/holiday">
        <FontAwesomeIcon icon={['fas', 'umbrella-beach']} />
      </Link>
    ),

    getItem(<Link to="/marketing"><IntlMessages id="sidebar.marketing" /></Link>, '6',
      <Link to="/marketing">
        <FontAwesomeIcon icon={['fas', 'bullhorn']} />
      </Link>
    ),

    getItem(<Link to="/preparation"><IntlMessages id="sidebar.preparation" /></Link>, '7',
      <Link to="/preparation">
        <FontAwesomeIcon icon={['fas', 'stopwatch']} />
      </Link>
    ),

    getItem(<Link to="/users"><IntlMessages id="sidebar.users" /></Link>, '8',
      <Link to="/users">
        <FontAwesomeIcon icon={['fas', 'users']} />
      </Link>
    ),

    getItem(<Link to="/documents"><IntlMessages id="sidebar.documents" /></Link>, '9',
      <Link to="/documents">
        <FontAwesomeIcon icon={['fas', 'folder']} />
      </Link>
    ),

    getItem(<Link to="/settings"><IntlMessages id="sidebar.settings" /></Link>, '10',
      <Link to="/settings">
        <FontAwesomeIcon icon={['fas', 'cog']} />
      </Link>
    ),

    getItem(<Link to="/help"><IntlMessages id="sidebar.help" /></Link>, '11',
      <Link to="/help">
        <FontAwesomeIcon icon={['fas', 'question-circle']} />
      </Link>
    ),

  ];

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={
              "light"
            }
            mode="inline"
            items={items}>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

