import { restaurant_restaurant_restaurant_menu_options } from '__generated__/restaurant';
import React from 'react';
import { useHistory } from 'react-router-dom';
import LogoSmartFood from 'assets/images/logo.png';

interface IDishProps {
  id?: number;
  isSelected?: boolean;
  description: string;
  name: string;
  price: number;
  restaurantId?: string,
  isCustomer?: boolean;
  coverImg: string;
  children?: React.ReactNode;
  orderStarted?: boolean;
  options?: restaurant_restaurant_restaurant_menu_options[] | null;
  addItemToOrder?: (dishId: number) => void;
  removeFromOrder?: (dishId: number) => void;
}

export const Dish: React.FC<IDishProps> = ({
  id = 0,
  isSelected,
  description,
  restaurantId,
  name,
  price,
  isCustomer = false,
  orderStarted = false,
  options,
  coverImg = '', 
  addItemToOrder,
  removeFromOrder,
  children: dishOptions,
}) => {
  
  const history = useHistory();

  const edit = () => {
    history.push(`/restaurants/${restaurantId}/edit-dish/${id}`)  
  };
  
  return (
    <div>
    <img src={coverImg || LogoSmartFood} alt="cover" width={300}/>
    <div
      className={`px-8 py-4 border transition-all ${isSelected ? 'border-gray-800' : 'hover:border-gray-800'
      }`}
    >
      <div className="mb-5">
        <h3 className="flex items-center text-lg font-medium">
          {name}{' '}
          <div className='flex'>
              <button
                className={`ml-3 py-1 px-3 cursor-pointer	text-sm  text-white bg-lime-600`}
                onClick={edit}
              >
                Edit
              </button>
              <button
                className={`ml-3 py-1 px-3 cursor-pointer	text-sm  text-white bg-red-500`}
                // onClick={onClick}
              >
                Remove
              </button>
            </div>
        </h3>
        <h4 className="font-medium">{description}</h4>
      </div>
      <span>$ {price}</span>
      {options && options?.length !== 0 && (
        <div>
          <h5 className="mt-6 mb-3 font-medium">Dish Options</h5>
          <div className="grid justify-start gap-2"> {dishOptions}</div>
        </div>
      )}
    </div>
    </div>
  );
};
