import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { useHistory } from 'react-router-dom';
import { useMe } from 'hooks/useMe';

const UserInfo = () => {

  const { data } = useMe();

  const dispatch = useDispatch();
  const history = useHistory();

  const logOut = () => {
    dispatch(userSignOut());
    localStorage.clear();
    history.push('/');
    window.location.reload();
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>Mi Cuenta</li>
      <li onClick={logOut}>Cerrar sesión
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://via.placeholder.com/150"}
          className="gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="gx-avatar-name">
          {data?.GetUser?.user?.username}
          <i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
      </Popover>
    </div>
  )

}

export default UserInfo;
