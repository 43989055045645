import { Helmet } from 'react-helmet-async';
import React from 'react';
import { Col, Row } from "antd";
import Auxiliary from "utils/Auxiliary";
import WelComeCard from "components/Dashboard/WelComeCard";
import SiteAudience from "components/Dashboard/SiteAudience";
import SiteVisit from "components/Dashboard/SiteVisit";
import IconWithTextCard from "components/Dashboard/IconWithTextCard";
import TotalRevenueCard from "components/Dashboard/TotalRevenueCard";
import TaskList from "components/Dashboard/TaskList";
import Overview from "components/Dashboard/Overview";
import TicketList from "components/Dashboard/TicketList";
import TaskByStatus from "components/Dashboard/TaskByStatus";
import NewCustomers from "components/Dashboard/NewCustomers";
import RecentActivity from "components/Dashboard/RecentActivity";
import GrowthCard from "components/Dashboard/GrowthCard";
import CurrencyCalculator from "components/Dashboard/CurrencyCalculator";
import Widget from "components/Widget/index";

export const Home = () => (
    <div className="my-4 container-fluid">
        <Helmet>
            <title>SMARTFOOD</title>
        </Helmet>
        <Auxiliary>
            <Row>
                <Col span={24}>
                    <div className="gx-card">
                        <div className="gx-card-body">
                            <Row>
                                <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                                    <WelComeCard />
                                </Col>

                                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                                    <SiteAudience />
                                </Col>

                                <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                    <SiteVisit />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                    <TotalRevenueCard />
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <NewCustomers />
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <GrowthCard trafficData={[
                        { name: 'Page A', value: 0 },
                        { name: 'Page B', value: 2000 },
                        { name: 'Page C', value: 600 },
                        { name: 'Page D', value: 4400 },
                        { name: 'Page D', value: 900 },
                        { name: 'Page H', value: 4860 },
                    ]} />
                </Col>

                <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
                    <Widget>
                        <RecentActivity recentList={[
                            {
                                id: 1,
                                day: 'Today',
                                tasks: [
                                    {
                                        id: 1,
                                        name: 'Mila Alba',
                                        title: [<span className="gx-link" key={1}>Mila Alba</span>, ' left a 5 star review on ',
                                        <span className="gx-link" key={2}>Albama’s House</span>],
                                        avatar: "https://via.placeholder.com/150",
                                        imageList: [],
                                    },
                                    {
                                        id: 2,
                                        name: 'Bob Builder',
                                        title: ['Callback request from ', <span className="gx-link" key={3}>Bob Builder</span>, ' for the property ',
                                            <span className="gx-link" key={4}>Dimitri House</span>],
                                        avatar: "https://via.placeholder.com/150",
                                        imageList: [],
                                    },
                                    {
                                        id: 4,
                                        name: 'Norman Dolphi',
                                        title: ['Norman Dolphi is looking for a house in New Jersy, USA'],
                                        avatar: '',
                                        imageList: [],
                                    },
                                ]
                            },
                            {
                                id: 2,
                                day: 'Yesterday',
                                tasks: [
                                    {
                                        id: 15,
                                        name: 'Kily Johns',
                                        title: ['Agent ',
                                            <span className="gx-link" key={7}>Kily Johns</span>, ' has added 7 new photos to the property ',
                                            <span key={6} className="gx-link">Albama’s House</span>],
                                        avatar: '',
                                        imageList: ["https://via.placeholder.com/150", "https://via.placeholder.com/150", "https://via.placeholder.com/150", "https://via.placeholder.com/150", "https://via.placeholder.com/150", "https://via.placeholder.com/150", "https://via.placeholder.com/150",],
                                    },
                                    {
                                        id: 6,
                                        name: 'Tom Moody',
                                        title: ['Welcome to a new agent ', <span className="gx-link" key={8}>Tom Moody in the Company</span>],
                                        avatar: "https://via.placeholder.com/150",
                                        imageList: [],
                                    },
                                    {
                                        id: 7,
                                        name: 'Oliver Shorter',
                                        title: [<span className="gx-link" key={9}>Oliver Shorter</span>, ' is looking for an office space in ',
                                        <span className="gx-link" key={10}>Colorado, USA</span>],
                                        avatar: "https://via.placeholder.com/150",
                                        imageList: [],
                                    }, {
                                        id: 8,
                                        name: 'Mila Alba',
                                        title: [<span className="gx-link" key={11}>Mila Alba</span>, ' left a 5 star review on ',
                                        <span className="gx-link" key={12}>Albama’s House</span>],
                                        avatar: "https://via.placeholder.com/150",
                                        imageList: [],
                                    },
                                ]
                            }]} shape="circle" />
                    </Widget>
                    <CurrencyCalculator />
                </Col>

                <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard iconColor={''} cardColor="cyan" icon="diamond" title="09" subTitle="Projects" />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard iconColor={''} cardColor="orange" icon="tasks" title="687" subTitle="Tasks" />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard iconColor={''} cardColor="teal" icon="team" title="04" subTitle="Teams" />
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard iconColor={''} cardColor="red" icon="files" title="09" subTitle="Files" />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <TaskList taskList={[
                                {
                                    id: 1,
                                    title: 'Make the home page dynamic',
                                    completed: false,
                                    user: {
                                        projectName: 'Chatbull',
                                        avatar: "https://via.placeholder.com/150"
                                    },
                                    dueDate: 'Tomorrow',
                                    tags: [1, 2]
                                }, {
                                    id: 2,
                                    title: 'Design wireframes based on the requirements',
                                    completed: false,
                                    user: {
                                        projectName: 'Wieldy',
                                        avatar: "https://via.placeholder.com/150"
                                    },
                                    dueDate: 'Today',
                                    tags: [2, 4]
                                }, {
                                    id: 3,
                                    title: 'Need to define microinteraction on click of signin button',
                                    completed: false,
                                    user: {
                                        projectName: 'Jumbo',
                                        avatar: "https://via.placeholder.com/150"
                                    },
                                    dueDate: '21 Jul',
                                    tags: [1, 2, 3]
                                }, {
                                    id: 4,
                                    title: 'New page need to be designed for property listing',
                                    completed: false,
                                    user: {
                                        projectName: 'Wieldy',
                                        avatar: "https://via.placeholder.com/150"
                                    },
                                    dueDate: '23 Jul',
                                    tags: [2, 3, 4]
                                }, {
                                    id: 5,
                                    title: 'Design wireframes based on the requirements',
                                    completed: false,
                                    user: {
                                        projectName: 'Wieldy',
                                        avatar: "https://via.placeholder.com/150"
                                    },
                                    dueDate: '1 Aug',
                                    tags: [2, 4]
                                }]} />
                        </Col>
                        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                            <TicketList />
                        </Col>
                        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                            <TaskByStatus />
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Overview />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Auxiliary>
    </div>
);
