import { 
    SET_ORDER,
    GET_ORDER
 } from "constants/ActionTypes";

export const GetOrder = () => {
  return {
    type: GET_ORDER
  }
};

export const SetOrderSelected = (payload) => {
  return {
    type: SET_ORDER,
    payload
  }
};


